import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTheme } from '../Flowbite/ThemeContext';
import { useModalContext } from './ModalContext';
export const ModalBody = ({ children, className, ...props }) => {
    const { popup } = useModalContext();
    const theme = useTheme().theme.modal.body;
    return (_jsx("div", { className: classNames(theme.base, {
            [theme.popup]: popup,
        }, className), ...props, children: children }));
};
