import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
export const Card = ({ children, className, horizontal, href, imgAlt, imgSrc, theme: customTheme = {}, ...props }) => {
    const Component = typeof href === 'undefined' ? 'div' : 'a';
    const theirProps = props;
    const theme = mergeDeep(useTheme().theme.card, customTheme);
    return (_jsxs(Component, { className: classNames(theme.root.base, theme.root.horizontal[horizontal ? 'on' : 'off'], href && theme.root.href, className), "data-testid": "flowbite-card", href: href, ...theirProps, children: [imgSrc && (_jsx("img", { alt: imgAlt ?? '', className: classNames(theme.img.base, theme.img.horizontal[horizontal ? 'on' : 'off']), src: imgSrc })), _jsx("div", { className: theme.root.children, children: children })] }));
};
