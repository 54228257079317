import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTheme } from '../Flowbite/ThemeContext';
import { SidebarItemContext } from './SidebarItemContext';
const SidebarItemGroup = ({ children, className, ...props }) => {
    const theme = useTheme().theme.sidebar.itemGroup;
    return (_jsx("ul", { className: classNames(theme, className), "data-testid": "flowbite-sidebar-item-group", ...props, children: _jsx(SidebarItemContext.Provider, { value: { isInsideCollapse: false }, children: children }) }));
};
SidebarItemGroup.displayName = 'Sidebar.ItemGroup';
export default SidebarItemGroup;
