import { useState } from "react";
import { Badge, Card, Tooltip } from "flowbite-react";
import { shrink } from "../utils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";

export default function NPubDisplay({ npub }: { npub: string }) {
  const [copied, setCopied] = useState<boolean>(false);
  return (
    <Tooltip
      content={
        <div className="flex flex-col items-center">
          <span className="text-sm">{npub}</span>
        </div>
      }
    >
      <div className="flex flex-row gap-1 items-center">
        <span className="text-lg text-gray-500 dark:text-gray-400 ">
          {shrink(npub, 5, 5)}
        </span>
        <CopyToClipboard
          text={npub}
          onCopy={() => {
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 500);
          }}
        >
          {copied ? (
            <Badge className="hover:cursor-text bg-purple-200 text-lg">
              Copied
            </Badge>
          ) : (
            <Badge className="hover:cursor-pointer bg-purple-200 hover:bg-purple-100 text-lg">
              Copy
            </Badge>
          )}
        </CopyToClipboard>
      </div>
    </Tooltip>
  );
}
