import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
export const Checkbox = forwardRef(({ theme: customTheme = {}, className, ...props }, ref) => {
    const theme = mergeDeep(useTheme().theme.checkbox, customTheme);
    return _jsx("input", { ref: ref, className: classNames(theme.root.base, className), type: "checkbox", ...props });
});
Checkbox.displayName = 'Checkbox';
