import { StrictMode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./i18n";
import Layout from "./Pages/Layout";
import NPListPage from "./Pages/NPListPage/NPListPage";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import KnowledgePage from "./Pages/KnowledgePage/KnowledgePage";
import ContactPage from "./Pages/ContactPage/ContactPage";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAbGas8VsVEG6j4E_jXnHliYHKaoiV2zUk",
  authDomain: "npub-nostr.firebaseapp.com",
  databaseURL: "https://npub-nostr-default-rtdb.firebaseio.com",
  projectId: "npub-nostr",
  storageBucket: "npub-nostr.appspot.com",
  messagingSenderId: "724732818612",
  appId: "1:724732818612:web:644b3c1c2c650aa5a553e1",
  measurementId: "G-06MQ53J85G",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

/**
 * HTTP query provider
 */
const HTTP = new QueryClient();

export const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <NPListPage />,
      },
      {
        path: "/nostr-knowledge",
        element: <KnowledgePage />,
      },
      {
        path: "/contact",
        element: <ContactPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <StrictMode>
    <QueryClientProvider client={HTTP}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </StrictMode>
);
