[
  {
    "name": "carl",
    "npub": "npub10qqd4j6g9qtl7vcrp45563xufjy20esmlq3vgydny09d3fepglhqltnmh4",
    "languages": ["zh"]
  },
  {
    "name": "TumbleBit",
    "npub": "npub156etfp73lfc2ax0ekj557sezuag0sjzdqhljm4v9tmda62nev4rqrtug7y",
    "languages": ["zh"]
  },
  {
    "name": "熊越",
    "npub": "npub1rsg3xkte3zyrn3m0jg0gwh2jpfszry0kfzew7q9tq5yq8sqjh9nquuas8t",
    "languages": ["zh"]
  },
  {
    "name": "超级君",
    "npub": "npub16a7m863zhkzpd5ezzkz08rlr900eateap5dwamynssysdv6hhhqsctuk9g",
    "languages": ["zh"]
  },
  {
    "name": "神鱼",
    "npub": "npub1ppgr0lsnx5l7hqulw4kcrhgctd3jqkuaxh6d0lftpd4alj2rct2qshk0zr",
    "languages": ["zh"]
  },
  {
    "name": "陈默",
    "npub": "npub1zv5zhy0f83kgqz99eqfya4f4wveznkx3aa86lxcvllpcrrc9qvdsz65dtd",
    "languages": ["zh"]
  },
  {
    "name": "余弦",
    "npub": "npub1n2prk4drfh570tc7qzvlcaa3pnj2fud7vu4jshymfp3w8sx2evpqx866nz",
    "languages": ["zh"]
  },
  {
    "name": "头雁",
    "npub": "npub1ks5hclkksh7ghd8v2058wkut3k35xucs372u96csl4mhsm29e7gsy9cs5r",
    "languages": ["zh"]
  },
  {
    "name": "民道",
    "npub": "npub15r9dfrldhzwu0s86dd6qmy6e8pka9pmncq29xxcmc0drg89dcqrqsk968a",
    "languages": ["zh"]
  },
  {
    "name": "郭宇",
    "npub": "npub1ztmzpw9w4ta375wktvj98lwt6y2jjc78spg60kwyvkhjty3cr8ysengahm",
    "languages": ["zh"]
  },
  {
    "name": "大姨妈",
    "npub": "npub1ana3ft0s7kznrjzu8maffwpfqd0lxj0nkv39gaqvwue7sse3w5uqmqdymm",
    "languages": ["zh"]
  },
  {
    "name": "和菜头",
    "npub": "npub1rnyzrnpdguv3k90uls8h8tld8x5x43hmxnal57vnaclq7qvxaa7q8n6u3e",
    "languages": ["zh"]
  },
  {
    "name": "陈威廉",
    "npub": "npub1fcn6gcrpn0p6p7yh54qnu4a5rqmns8axg5af46ylytlx0t092gfqy6y7su",
    "languages": ["zh"]
  },
  {
    "name": "RON",
    "npub": "npub1hn8hq6gha0vpcvpgf45z5vng987qrd4v54mw4rgu09hguzzvk83qy58enw",
    "languages": ["zh"]
  },
  {
    "name": "林嘉鹏",
    "npub": "npub15tn7q4l92jaav50rlah67v42x57rlm8v0vh4v0dv69g485973yeqyq7pk8",
    "languages": ["zh"]
  },
  {
    "name": "雕刻时光",
    "npub": "npub1lwdt2m9xp8pcleellcdpkyf973mfwlxjfcdvs47jldatc60c6gfqzlf5rx",
    "languages": ["zh"]
  },
  {
    "name": "陈子豪",
    "npub": "npub1x8sh8lm7h8kpwq0njsyf35yelz9qxd2fhxzc9jp344yz5093p4rsvth5vj",
    "languages": ["zh"]
  },
  {
    "name": "元杰",
    "npub": "npub1u8jx386gvmuk0r78zwtfgyr8pfflwm9wt2tp4djzdr7w49h09leqhhdwnm",
    "languages": ["zh"]
  },
  {
    "name": "行长",
    "npub": "npub10xz6jj4xh5kjztusd2vuwmsp6dua7zcyqzwgnvavdwxf3uva8kxqjq03xq",
    "languages": ["zh"]
  },
  {
    "name": "王峰",
    "npub": "npub1n6fa79g7wfqn2fg3x9fkfw7zn00zqe8xr8j2n0je4lk5kf7k855s68y46y",
    "languages": ["zh"]
  },
  {
    "name": "孔剑平",
    "npub": "npub1qkgrzq3kxdrxsgq7cutkk9pdahw3yzmtgdc2a2vnexxu7qt8ta0sywa0qm",
    "languages": ["zh"]
  },
  {
    "name": "大硕",
    "npub": "npub104ce7lgt485p6r09cfwldpjsza0sg2dghu4cr5hv73zdvc59mv0qt3hatz",
    "languages": ["zh"]
  },
  {
    "name": "杨虎成",
    "npub": "npub1km9x5x7cdj79xquyqx3dy5gc3cyamwqp27eylashxskyap7rt7sstfxpur",
    "languages": ["zh"]
  },
  {
    "name": "星主",
    "npub": "npub1rfvtnvxmks4rng82g84zjh4nhhtufgh5x65jpcmfxl0dslqlsgnsv7wx05",
    "languages": ["zh"]
  },
  {
    "name": "jialin",
    "npub": "npub1zx6mydeeqwhpcvdkyx83r8t9m2yesgyy5tdd4496fqyavgrmnkjqehqzqy",
    "languages": ["zh"]
  },
  {
    "name": "王大拿",
    "npub": "npub1emx34qaf9lszh30qllvscdeaq9eahlk47e2dkyfphwc66tmva3yqq32tjh",
    "languages": ["zh"]
  },
  {
    "name": "买牛",
    "npub": "npub1rxl5l333zkhwwdd0pzhxkscfny95zthxz4xtn28y8t7drxndyl4qwxwnde",
    "languages": ["zh"]
  },
  {
    "name": "孔华威",
    "npub": "npub1sxrq370gs8w23vuq2a3tpw3j8wv0ehyvajfvp5ptnjsdj4epp9sq6ca5pf",
    "languages": ["zh"]
  },
  {
    "name": "朱砝",
    "npub": "npub1qazh05fcjek2vgs8qwkr5k5la4jekwvvuw98queaclykz6uemr9q9dvnwv",
    "languages": ["zh"]
  },
  {
    "name": "吕国宁",
    "npub": "npub1p7wwm0e82jtslz4r95nmzudtcnduumyuaq7qpjh52cg623540jds7agqnd",
    "languages": ["zh"]
  },
  {
    "name": "彭松",
    "npub": "npub15vdxr28yz7cwmh889tzgvqm6v6jtpsmlmk9u2m3yg2z0swadh6eshc6dux",
    "languages": ["zh"]
  },
  {
    "name": "成笑",
    "npub": "npub18yfe75urapvepvk3vnml5qf9vkrrfxh0mv90xhzfq00jh7hpupqqlf8z24",
    "languages": ["zh"]
  },
  {
    "name": "orangefans",
    "npub": "npub1p3xl5e283x6yxshhwqyldyzwmshzwqhxpk93e00tlpzelszt5tmsx90qfe",
    "languages": ["zh"]
  },
  {
    "name": "jack",
    "twitter": "@jack",
    "desc": "#bitcoin #nostr: npub1sg6plzptd64u62a878hep2kev88swjh3tw00gjsfl8f237lmu63q0uf63m",
    "npub": "npub1sg6plzptd64u62a878hep2kev88swjh3tw00gjsfl8f237lmu63q0uf63m",
    "languages": ["zh", "en"]
  },
  {
    "name": "Edward Snowden",
    "twitter": "@Snowden",
    "desc": "You can find me on #nostr @ pubkey: npub1sn0wdenkukak0d9dfczzeacvhkrgz92ak56egt7vdgzn8pv2wfqqhrjdv9 . (use Damus on iOS, Amethyst on Android, or http://snort.social)",
    "npub": "npub1sn0wdenkukak0d9dfczzeacvhkrgz92ak56egt7vdgzn8pv2wfqqhrjdv9",
    "languages": ["zh", "en"]
  },
  {
    "name": "zendealer - Best BTC + Nostr retweets",
    "twitter": "@zendealer",
    "desc": "#Bitcoin + #Nostr npub1yf2dde9trhlny8wvasmsu7stepkfr90meew9dner8makjrcujhyq98ftyv",
    "npub": "npub1yf2dde9trhlny8wvasmsu7stepkfr90meew9dner8makjrcujhyq98ftyv",
    "languages": ["en"]
  },
  {
    "name": "Willy Woo",
    "twitter": "@woonomic",
    "desc": "#Bitcoin analyst. Crest Fund, we invest in quant funds. If that's you, contact us → data@crest.fund Nostr: npub1clwvhf87gsn20v02ywd9vda6gratnp3v3jrtxvc0ue0f7e",
    "npub": "npub1clwvhf87gsn20v02ywd9vda6gratnp3v3jrtxvc0ue0f7e",
    "languages": ["en"]
  },
  {
    "name": "₿AEKCHAN 백찬",
    "twitter": "@BAEKCHAN_129",
    "desc": "#Bitcoin l Kpop Artist l Music Producer  #nostr : npub130av8p08fuu9lr9zaxz2xmuwedggqlfescvy66cp7ud7hhethrcs8qxana",
    "npub": "npub130av8p08fuu9lr9zaxz2xmuwedggqlfescvy66cp7ud7hhethrcs8qxana",
    "languages": ["en"]
  },
  {
    "name": "MF_HODL is on nostr",
    "twitter": "@MF_HODL",
    "desc": "#npub13z3v8dpqkjszwur2npsq687hgjkxelgjugqmwsvfhe00fv4n4fzsfu3fzc",
    "npub": "npub13z3v8dpqkjszwur2npsq687hgjkxelgjugqmwsvfhe00fv4n4fzsfu3fzc",
    "languages": ["en"]
  },
  {
    "name": "Preston Pysh",
    "twitter": "@PrestonPysh",
    "desc": "Bitcoin & books. Nostr = Freedom of Speech. Nostr Verification: npub1s5yq6wadwrxde4lhfs56gn64hwzuhnfa6r9mj476r5s4hkunzgzqrs6q7z",
    "npub": "npub1s5yq6wadwrxde4lhfs56gn64hwzuhnfa6r9mj476r5s4hkunzgzqrs6q7z",
    "languages": ["en"]
  },
  {
    "name": "magic internet moneyist",
    "twitter": "@notgrubles",
    "desc": "#Bitcoin doesn't care. #nostr: #npub1ate84ggysvau69hkw9ygkqwktak6xqtrkkzg465eva7vj37aqz4qqwmf3z",
    "npub": "npub1ate84ggysvau69hkw9ygkqwktak6xqtrkkzg465eva7vj37aqz4qqwmf3z",
    "languages": ["en"]
  },
  {
    "name": "Guy Swann ",
    "twitter": "@TheGuySwann",
    "desc": "Host of  @BitcoinAudible ,  @ShitcoinInsider   • Top Quality Memecraft nostr:#npub1h8nk2346qezka5cpm8jjh3yl5j88pf4ly2ptu7s6uu55wcfqy0wq36rpev",
    "npub": "npub1h8nk2346qezka5cpm8jjh3yl5j88pf4ly2ptu7s6uu55wcfqy0wq36rpev",
    "languages": ["en"]
  },
  {
    "name": "Mandrik",
    "twitter": "@Mandrik",
    "desc": "Sold baklava for #Bitcoin in 2011 #Nostr: npub1qex7yjtuucs6ac49kjujdgytrjsphn5a4pdscu2w3qlprym4zsxqfz82qk mandrik@nostrplebs.com Past:  @blockchain / @BitInstant",
    "npub": "npub1qex7yjtuucs6ac49kjujdgytrjsphn5a4pdscu2w3qlprym4zsxqfz82qk",
    "languages": ["en"]
  },
  {
    "name": "ChartsBTC",
    "twitter": "@ChartsBtc",
    "desc": "A bitcoiner with a spreadsheet. #DCA #HODL #bitcoin Nostr npub1umh9k3yuygx7l6nrwwu20c28e27k0s4ak5qk3p4lvzt2836rtfsse626me",
    "npub": "npub1umh9k3yuygx7l6nrwwu20c28e27k0s4ak5qk3p4lvzt2836rtfsse626me",
    "languages": ["en"]
  },
  {
    "name": "ck (going to Bitcoin 2023)",
    "twitter": "@ck_SNARKs",
    "desc": "Home cooking + Bitcoin | GM  @BitcoinMagazine   @TheBitcoinConf  | Know Nothing | Nostr: npub1cf3zeytdnwgwzz5pk2ax0vvmmlzad03xcft4d50ejrfhsh8pxcdsefx7gk ",
    "npub": "npub1cf3zeytdnwgwzz5pk2ax0vvmmlzad03xcft4d50ejrfhsh8pxcdsefx7gk",
    "languages": ["en"]
  },
  {
    "name": "William Casarin ",
    "twitter": "@jb55",
    "desc": "#bitcoin core,& #nostr dev. Building  @damusapp  nostr:#npub1xtscya34g58tk0z605fvr788k263gsu6cy9x0mhnm87echrgufzsevkk5s",
    "npub": "npub1xtscya34g58tk0z605fvr788k263gsu6cy9x0mhnm87echrgufzsevkk5s",
    "languages": ["zh", "en"]
  },
  {
    "name": "River",
    "twitter": "@River",
    "desc": "Buy and mine #Bitcoin. Zero-fee DCA. 100% reserve custody. Lightning  Nostr: npub1xkere5pd94672h8w8r77uf4ustcazhfujkqgqzcykrdzakm4zl4qeud0en",
    "npub": "npub1xkere5pd94672h8w8r77uf4ustcazhfujkqgqzcykrdzakm4zl4qeud0en",
    "languages": ["en"]
  },
  {
    "name": "Christophe Gevrey",
    "twitter": "@cri",
    "desc": "Tech evangelist  @IATA  - Former  @Reuters . 999 Personal account. For work related queries: http://linkedin.com/in/gevrey/ #nostr: npub1sdxz3aug9yjsddlh24ch5pwacwk",
    "npub": "npub1sdxz3aug9yjsddlh24ch5pwacwk",
    "languages": ["en"]
  },
  {
    "name": "DK",
    "twitter": "@dksf",
    "desc": "startup storyteller, nostr/bitcoin student, early googler, always curious -nostr:npub1kuy0wwf0tzzqvgfv8zpw0vaupkds3430jhapwrgfjyn7ecnhpe0qj9kdj8",
    "npub": "npub1kuy0wwf0tzzqvgfv8zpw0vaupkds3430jhapwrgfjyn7ecnhpe0qj9kdj8",
    "languages": ["en"]
  },
  {
    "name": "Kyle Torpey",
    "twitter": "@kyletorpey",
    "desc": "#Bitcoin Writer | #Nostr pubkey: npub1pt533g8adyur60sufuug8lk33et0vmelzy2zh565rpg8qwxjykxqjtwx9a",
    "npub": "npub1pt533g8adyur60sufuug8lk33et0vmelzy2zh565rpg8qwxjykxqjtwx9a",
    "languages": ["en"]
  },
  {
    "name": "Dilution-proof",
    "twitter": "@dilutionproof",
    "desc": "Fascinated by #Bitcoin's potential as the base-layer of a sound & self-sovereign future Nostr: npub1tlnmatnpznq4djxysleje5247kumu9hw7k3frqs6qh7e0yaf3kksvltsg5",
    "npub": "npub1tlnmatnpznq4djxysleje5247kumu9hw7k3frqs6qh7e0yaf3kksvltsg5",
    "languages": ["en"]
  },
  {
    "name": "_Checkɱate ",
    "twitter": "@_Checkmatey_",
    "desc": "Lead On-chain Analyst  @glassnode  Bitcoiner + Onchain Analyst Creator of http://checkonchain.com nostr: npub1qh5sal68c8swet6ut0w5evjmj6vnw29x3k967h7atn45unzjyeyq6ceh9r",
    "npub": "npub1qh5sal68c8swet6ut0w5evjmj6vnw29x3k967h7atn45unzjyeyq6ceh9r",
    "languages": ["en"]
  },
  {
    "name": "BTC Sessions ",
    "twitter": "@BTCsessions",
    "desc": "Host of the BTC Sessions on YouTube. btcsessions@getalby.com nostr:#npub1rxysxnjkhrmqd3ey73dp9n5y5yvyzcs64acc9g0k2epcpwwyya4spvhnp8",
    "npub": "npub1rxysxnjkhrmqd3ey73dp9n5y5yvyzcs64acc9g0k2epcpwwyya4spvhnp8",
    "languages": ["en"]
  },
  {
    "name": "GregZaj1",
    "twitter": "@gregzaj1",
    "desc": "#Bitcoin and Dank Memes. Opinions are my own. This is a comedy account. Nostr: npub1zh36wt34eav4tugmd3r7f4tr99suj6p9ps2ezneugdvyfvrw3rpqtx4qq9",
    "npub": "npub1zh36wt34eav4tugmd3r7f4tr99suj6p9ps2ezneugdvyfvrw3rpqtx4qq9",
    "languages": ["en"]
  },
  {
    "name": "Sam Callahan",
    "twitter": "@samcallah",
    "desc": "Lead Analyst  @swanbitcoin   | Host of Swan Signal | nostr: npub1fpewuyx9rekj9487v2uawmg2any7tm7hpfw9at4q2r42u9jk792qg9vrkt",
    "npub": "npub1fpewuyx9rekj9487v2uawmg2any7tm7hpfw9at4q2r42u9jk792qg9vrkt",
    "languages": ["en"]
  },
  {
    "name": "Abubakar Nur Khalil",
    "twitter": "@ihate1999",
    "desc": " ₿itcoin Core contributor |  @rcrsvcapital  |  @btrustteam  |  @QalaAfrica  |  #nostr : npub1sn0q3zptdcm8qh8ktyhwtrnr9htwpykav8qnryhusr9mcr9ustxqe4tr2x",
    "npub": "npub1sn0q3zptdcm8qh8ktyhwtrnr9htwpykav8qnryhusr9mcr9ustxqe4tr2x",
    "languages": ["en"]
  },
  {
    "name": "Andrew Haigh",
    "twitter": "@AndrewHaigh",
    "desc": "Content | Product | Journalism. Ex  @Twitter   @TheSun . Proud Dad, Yorkshireman, #lufc fan. #nostr: npub1f3fy9vymfc3tf064xx75dr2yn9rtngn79tqgngp9maygvkvlltpsdmms3d",
    "npub": "npub1f3fy9vymfc3tf064xx75dr2yn9rtngn79tqgngp9maygvkvlltpsdmms3d",
    "languages": ["en"]
  },
  {
    "name": "George Mekhail",
    "twitter": "@gmekhail",
    "desc": "Head of Community  @bitcoinmagazine . co-author Thank God for #Bitcoin. nostr: #npub1z85hnvnug2ap6qrup4dr4f8m53aa6tvrfk8y6pqd4te2l0ulkuzqsvy9dq",
    "npub": "npub1z85hnvnug2ap6qrup4dr4f8m53aa6tvrfk8y6pqd4te2l0ulkuzqsvy9dq",
    "languages": ["en"]
  },
  {
    "name": "mike",
    "twitter": "@bitcoins",
    "desc": "Bitcoin researcher  @bitcoinbank  Nostr: #npub1h09hxywtmzf2z7ff0dkktf08a8ezfawt9u39m2auv74dal8yuecssj32yv",
    "npub": "npub1h09hxywtmzf2z7ff0dkktf08a8ezfawt9u39m2auv74dal8yuecssj32yv",
    "languages": ["en"]
  },
  {
    "name": "Tim Poastor",
    "twitter": "@timpastoor",
    "desc": "#Nostr: npub1yn8hfcgjtqe7jaftfpp79zraahwldyggjmnws23daa5vs5napq2qex6pzj #Mastodon: @timp@x0f.org",
    "npub": "npub1yn8hfcgjtqe7jaftfpp79zraahwldyggjmnws23daa5vs5napq2qex6pzj",
    "languages": ["en"]
  },
  {
    "name": "Hugo Nguyen",
    "twitter": "@hugohanoi",
    "desc": "Founder  @nunchuk_io . I chain, therefore I am  Nostr: npub1qp302p7ry80x8xpcafm4d4szpxvzy8r70lyveg39gk0xgycwu35s06yn58",
    "npub": "npub1qp302p7ry80x8xpcafm4d4szpxvzy8r70lyveg39gk0xgycwu35s06yn58",
    "languages": ["en"]
  },
  {
    "name": "Don McAllister - @donmcallister@mastodon.social",
    "twitter": "@donmcallister",
    "desc": "Nostr Pub Key : npub1g0rnur82mave56m6cynsjw5jnhfd2jtp6lfquwja722apjgved0sn86sls",
    "npub": "npub1g0rnur82mave56m6cynsjw5jnhfd2jtp6lfquwja722apjgved0sn86sls",
    "languages": ["en"]
  },
  {
    "name": "Alexander Leishman ",
    "twitter": "@Leishman",
    "desc": "CEO & CTO  @River . #Bitcoin. Keeping the fire of human liberty shining bright  #nostr: npub139nl9yxvwayl60fr97m3zrq9md6x5v0uup344mkyuyg6mzlusyxs4zkwf4",
    "npub": "npub139nl9yxvwayl60fr97m3zrq9md6x5v0uup344mkyuyg6mzlusyxs4zkwf4",
    "languages": ["en"]
  },
  {
    "name": "Crown #Bitcoin",
    "twitter": "@barackomaba",
    "desc": "Full time #Bitcoin - #nostr npub1jccr0wnu25laenc9hz6g0es0sru04uhqzd8jvgq0m7thyu605tlss8sgfh - Follow Blixt lightning wallet developer  @hampus_s",
    "npub": "npub1jccr0wnu25laenc9hz6g0es0sru04uhqzd8jvgq0m7thyu605tlss8sgfh",
    "languages": ["en"]
  },
  {
    "name": "This is Bullish",
    "twitter": "@thisisbullish",
    "desc": "#nostr: npub1v9ya8dauawkzm4mxuwcz3zr0qknmam0985mx58smg8tmk5jt0rrqx3jcqt",
    "npub": "npub1v9ya8dauawkzm4mxuwcz3zr0qknmam0985mx58smg8tmk5jt0rrqx3jcqt",
    "languages": ["en"]
  },
  {
    "name": "jack pinky",
    "twitter": "@pinkyhuh",
    "desc": "nostr: npub1tw4kwl6ttpfmqvt7sy7pq2hx0hyg2uy5jhz6ahtv4hngn96kn3hqdeazyj",
    "npub": "npub1tw4kwl6ttpfmqvt7sy7pq2hx0hyg2uy5jhz6ahtv4hngn96kn3hqdeazyj",
    "languages": ["en"]
  },
  {
    "name": "LN Markets",
    "twitter": "@LNMarkets",
    "desc": "Building self-custody trading on #Bitcoin  http://linktr.ee/lnmarkets #nostr: npub1lnm0ac8ft8r3jhddchekledgwvqrkwy7wqejjwcxq47gy87te8zs6utnnn",
    "npub": "npub1lnm0ac8ft8r3jhddchekledgwvqrkwy7wqejjwcxq47gy87te8zs6utnnn",
    "languages": ["en"]
  },
  {
    "name": "Plan Marcus ",
    "twitter": "@plan_marcus",
    "desc": "#Bitcoin Disclaimer:entertainment / meme account. logging stuff.  @coinbitsAPP  ambassador NOSTR: npub1q5gsrnwffpp5jzdpvxw54xc0mrlghjtgd66y92fc3nepnl9kmlqqx6ve9h",
    "npub": "npub1q5gsrnwffpp5jzdpvxw54xc0mrlghjtgd66y92fc3nepnl9kmlqqx6ve9h",
    "languages": ["en"]
  },
  {
    "name": "ThibaudMarechal.com",
    "twitter": "@thibm_",
    "desc": "#bitcoin nostr npub1cq6t0sz84xm5zasmtzmwskjn6e55hc2xe78fzy4jjju25w69zj2q8ttsu7",
    "npub": "npub1cq6t0sz84xm5zasmtzmwskjn6e55hc2xe78fzy4jjju25w69zj2q8ttsu7",
    "languages": ["en"]
  },
  {
    "name": "softsimon",
    "twitter": "@softsimon_",
    "desc": "#Bitcoin is life. Running  @mempool  #nostr: npub1hvw02fgyxhl5whxckv4vkglraeamar7r3a54zuztg7v9zw28vukq5a5e8q",
    "npub": "npub1hvw02fgyxhl5whxckv4vkglraeamar7r3a54zuztg7v9zw28vukq5a5e8q",
    "languages": ["en"]
  },
  {
    "name": "TK",
    "twitter": "@TiKawamoto",
    "desc": "rational anarchist Bitcoin enjoyer artist #nostr: npub1tsavtyhykyhx90w8e966ysrltpyyh7wgzmgu9x049ujxj9pv5w8qe7dhv3",
    "npub": "npub1tsavtyhykyhx90w8e966ysrltpyyh7wgzmgu9x049ujxj9pv5w8qe7dhv3",
    "languages": ["en"]
  },
  {
    "name": "₿obby @ Nostr",
    "twitter": "@bobby_vec",
    "desc": "#Bitcoin Max & Node runner || Find me on Nostr - npub1uf5jv3s6d8sqwqvxw80r38r45gvrmeq9mvvsnk50f5dlxdjnq7hqdfnkty",
    "npub": "npub1uf5jv3s6d8sqwqvxw80r38r45gvrmeq9mvvsnk50f5dlxdjnq7hqdfnkty",
    "languages": ["en"]
  },
  {
    "name": "Dany",
    "twitter": "@DanyAlos",
    "desc": "#Bitcoin pleb, fine arts lover, carnivore. #nostr npub18yk6hg82ynd78legw44cut3y47ghvn6m76y83d9c0age9f7mv8ns8ccsvn dany@nostrplebs.com English and Spanish",
    "npub": "npub18yk6hg82ynd78legw44cut3y47ghvn6m76y83d9c0age9f7mv8ns8ccsvn",
    "languages": ["en"]
  },
  {
    "name": "David A. Johnston",
    "twitter": "@DJohnstonEC",
    "desc": "Chief Strategy Officer at http://DLTx.com Coined the term \"Dapps\" in 2013. Decentralize. Nostr npub1uzrm5cc2yh7fzx5h54rg4xyjaxc7ve0j5xj9lahzjtttqxkkznps74td44",
    "npub": "npub1uzrm5cc2yh7fzx5h54rg4xyjaxc7ve0j5xj9lahzjtttqxkkznps74td44",
    "languages": ["en"]
  },
  {
    "name": "Alex Holmes",
    "twitter": "@Trojan81",
    "desc": "2 Time National Champion at USC. Seeker of Truth  Nostr npub1p353z6hz0a9emtwcphnsrwluyc09xg2nuww3jt3l2ls8mcjmedlq6ae5fd",
    "npub": "npub1p353z6hz0a9emtwcphnsrwluyc09xg2nuww3jt3l2ls8mcjmedlq6ae5fd",
    "languages": ["en"]
  },
  {
    "name": "Dylan Bathurst",
    "twitter": "@dylanbathurst",
    "desc": "#nostr:npub1jcazmj3futk4vcaxy76j38knd4z92vdr7hh3yackyf7c5x4229nqquflp2  @get_lunchmoney , past:  @Blockchain   @PurseIO   @ebay   @Rumgr  #christian #bitcoin",
    "npub": "npub1jcazmj3futk4vcaxy76j38knd4z92vdr7hh3yackyf7c5x4229nqquflp2",
    "languages": ["en"]
  },
  {
    "name": "Blue Collar Bitcoin (a podcast)",
    "twitter": "@Blue_CollarBTC",
    "desc": "Firefighters explore economics, finance & #BTC | Josh & Dan shared profile | NOSTR: npub1a3hrd4wfawr578d5y5l0qgmh7lx8q6tumfq0h7eymmttt52veexqkcfg37",
    "npub": "npub1a3hrd4wfawr578d5y5l0qgmh7lx8q6tumfq0h7eymmttt52veexqkcfg37",
    "languages": ["en"]
  },
  {
    "name": "BRRRRRRRR",
    "twitter": "@btcfeen",
    "desc": "BTC is going to 250k and there’s nothing you can do about it #bitcoin nostr: npub1x6h3prp8d9xn0dmsw0xsgnhuraw84zsy8de0pak9kly3a9fh0zmqsg0hte",
    "npub": "npub1x6h3prp8d9xn0dmsw0xsgnhuraw84zsy8de0pak9kly3a9fh0zmqsg0hte",
    "languages": ["en"]
  },
  {
    "name": "SELF₿ANKT",
    "twitter": "@selfbankt",
    "desc": "#Bitcoin Fixing anything before fixing money is futile. Building  @farmfoodmap  #nostr: npub13fz04jej7nktvtvg24dj926a5ctcmaw6ekp9028eux3pz3csxdrqgd77yu",
    "npub": "npub13fz04jej7nktvtvg24dj926a5ctcmaw6ekp9028eux3pz3csxdrqgd77yu",
    "languages": ["en"]
  },
  {
    "name": "Max Webster",
    "twitter": "@MaxAWebster",
    "desc": "max@getalby.com #nostr: npub18lzls4f6h46n43revlzvg6x06z8geww7uudhncfdttdtypduqnfsagugm3 Helping entrepreneurs build the hivemind. And I rap: http://bit.ly/2SxBo4d",
    "npub": "npub18lzls4f6h46n43revlzvg6x06z8geww7uudhncfdttdtypduqnfsagugm3",
    "languages": ["en"]
  },
  {
    "name": "girevik",
    "twitter": "@girevik_",
    "desc": "nostr: #npub1gcad7z2zd6qh6znx5us4u3wszmaw0zu5k6pmfktt40c65t5f5a5s3zlc75",
    "npub": "npub1gcad7z2zd6qh6znx5us4u3wszmaw0zu5k6pmfktt40c65t5f5a5s3zlc75",
    "languages": ["en"]
  },
  {
    "name": "Justin Dorey",
    "twitter": "@Justin_dorey",
    "desc": "Business Analyst  @DeloitteCanada  • Former Olympic Freeskier • UBC Sauder Alum • #bitcoin • Nostr npub1sdldck3ksvhqx979wj8qqhk979xq000vn54cm3ekspedmj0a2frqyqwdfs",
    "npub": "npub1sdldck3ksvhqx979wj8qqhk979xq000vn54cm3ekspedmj0a2frqyqwdfs",
    "languages": ["en"]
  },
  {
    "name": "dhruv",
    "twitter": "@dhruv",
    "desc": "#bitcoin core contributor working on http://bip324.com supported by  @spiralbtc   @superlunarhq  nostr: npub199auzc6hkv2tu2gu3ym4tvjav6vec9f9h0e4xlauvdaqcanlzjas0975lv",
    "npub": "npub199auzc6hkv2tu2gu3ym4tvjav6vec9f9h0e4xlauvdaqcanlzjas0975lv",
    "languages": ["en"]
  },
  {
    "name": "Will Cole",
    "twitter": "@willcole",
    "desc": "#Bitcoin #Nostr: npub1du6sgl90wse0cz44fg50a4kg9ea4sgctlxps90ccx58lw8ssgv9qhjyf3c",
    "npub": "npub1du6sgl90wse0cz44fg50a4kg9ea4sgctlxps90ccx58lw8ssgv9qhjyf3c",
    "languages": ["en"]
  },
  {
    "name": "Abelito",
    "twitter": "@AbelitoPanama",
    "desc": "#Bitcoin Maximus. Consumption minimus. Nostr Pubkey: npub1zd0lzj2qaml3pr0fm5wd9mpy90f5xpt085re8lqcpgm5tf6yst3qy38eeq",
    "npub": "npub1zd0lzj2qaml3pr0fm5wd9mpy90f5xpt085re8lqcpgm5tf6yst3qy38eeq",
    "languages": ["en"]
  },
  {
    "name": "#Bitcoin Cowrieskumi@8333.mobi",
    "twitter": "@CowriesKumi",
    "desc": "Building  @BitcoinCowries_  &  @MySatsFarm Broadcast Journalist //  #Bitcoin  nostr:#npub1yyt2uhvjsdfqpfuqe4e9k0ft4cy6ln0z2jv2unvywj49hmhx4tkqw0vz67",
    "npub": "npub1yyt2uhvjsdfqpfuqe4e9k0ft4cy6ln0z2jv2unvywj49hmhx4tkqw0vz67",
    "languages": ["en"]
  },
  {
    "name": "Fully Noded",
    "twitter": "@FullyNoded",
    "desc": "Your node, your Bitcoin. PGP: 1C72 2776 3647 A221 6E02 E539 025E 9AD2 D3AC 0FCA Nostr: npub1hpzcx0sxmy8alvv4gl8zrzj3x4ndv2g526nh85nhe6x5wevqk44s4cgyww",
    "npub": "npub1hpzcx0sxmy8alvv4gl8zrzj3x4ndv2g526nh85nhe6x5wevqk44s4cgyww",
    "languages": ["en"]
  },
  {
    "name": "naman",
    "twitter": "@namanmtl",
    "desc": "Scaling #Web3 with  @TrustWallet  and  @Binance  retweets≠endorsements #nostr: npub1rj8rpat6qlfzrm0m20qqgnl5rx9nsrz5yrag0ynha6ndjzuch26sll0kr2",
    "npub": "npub1rj8rpat6qlfzrm0m20qqgnl5rx9nsrz5yrag0ynha6ndjzuch26sll0kr2",
    "languages": ["en"]
  },
  {
    "name": "Nostrich",
    "twitter": "@Nostr_Ostrich",
    "desc": "MY PRIMARY PURPOSE IS TO SHARE OSTRICH FACTS NO NOSTR PROMO #npub1khd34txqv7s9vdgvfl925r6ytj8j4janalpq08z342ap7dwds3jskny9x4 created by  @WalkerAmerica",
    "npub": "npub1khd34txqv7s9vdgvfl925r6ytj8j4janalpq08z342ap7dwds3jskny9x4",
    "languages": ["en"]
  },
  {
    "name": "Sam Wouters",
    "twitter": "@SDWouters",
    "desc": "#Bitcoin Research Analyst at  @River  Nostr: npub1vwlazqm4rearynuef92ccxw5jwa6kgkupztxvz3lklj9ehqv9xpq8wr934",
    "npub": "npub1vwlazqm4rearynuef92ccxw5jwa6kgkupztxvz3lklj9ehqv9xpq8wr934",
    "languages": ["en"]
  },
  {
    "name": "Bitcoin Carl ",
    "twitter": "@BitcoinCarl_",
    "desc": "#bitcoin nostr: npub1a3vmh85t4ngfjs088enlhq9rpev7htsa4uyug9s78ernmhq4zunsvtuf3l",
    "npub": "npub1a3vmh85t4ngfjs088enlhq9rpev7htsa4uyug9s78ernmhq4zunsvtuf3l",
    "languages": ["en"]
  },
  {
    "name": "Dread",
    "twitter": "@PoleVaultDream",
    "desc": "#Bitcoin #Freedom dirtbag | lightning pleb | shadowy s̶u̶p̶e̶r̶ selassie coder | nostr: npub1qqqqqq0u2gj96tdfvqymdqn739k4s0h9rzdwyegfmalv28j7a5ssh5ntu2",
    "npub": "npub1qqqqqq0u2gj96tdfvqymdqn739k4s0h9rzdwyegfmalv28j7a5ssh5ntu2",
    "languages": ["en"]
  },
  {
    "name": "NostrPancho",
    "twitter": "@NostrPancho",
    "desc": "#Bitcoin #Nostr PubKey: npub19hlk7245yllkwsvp0hn0vxj6zh6huc4wwlgjkgy4jr0r9tf0qw9sxsr94q Hex: 2dff6f2ab427ff6741817de6f61a5a15f57e62ae77d12b209590de32ad2f038b",
    "npub": "npub19hlk7245yllkwsvp0hn0vxj6zh6huc4wwlgjkgy4jr0r9tf0qw9sxsr94q",
    "languages": ["en"]
  },
  {
    "name": "nostr names",
    "twitter": "@nostr4u",
    "desc": "nostr names 4 u...DM npub1skj7frjydwarlssyppwq6lmw4xlz5autmx7lg7jmgxregvfuwecqfu828g",
    "npub": "npub1skj7frjydwarlssyppwq6lmw4xlz5autmx7lg7jmgxregvfuwecqfu828g",
    "languages": ["en"]
  },
  {
    "name": "Jed Bridges",
    "twitter": "@JedBridges",
    "desc": "Autarky for all! Believer, father and husband. Designer working on freedom systems with #Bitcoin @ (redacted). Nostr: #npub1ar22w5yjgmfnstv0mwsklwjvmzgy2rgeg6sp",
    "npub": "npub1ar22w5yjgmfnstv0mwsklwjvmzgy2rgeg6sp",
    "languages": ["en"]
  },
  {
    "name": "sri  #nostr",
    "twitter": "@srinathHD",
    "desc": "#npub1az34pwxzx588tf6ymz4s3j052glsnplkyehglg2z85sf8f7pe09q46s70g",
    "npub": "npub1az34pwxzx588tf6ymz4s3j052glsnplkyehglg2z85sf8f7pe09q46s70g",
    "languages": ["en"]
  },
  {
    "name": "realNostr",
    "twitter": "@realNostr",
    "desc": "#NOSTR public key npub1t2kjdshpyrqfa0yhvg0ltmzvhhvfnu9e3zmu9wsjhax4dcdhrlcq409wjz",
    "npub": "npub1t2kjdshpyrqfa0yhvg0ltmzvhhvfnu9e3zmu9wsjhax4dcdhrlcq409wjz",
    "languages": ["en"]
  },
  {
    "name": "Umang Jaipuria",
    "twitter": "@umang",
    "desc": "Built products and product teams @ Plaid, Twitter, Amazon. nostr: npub1yjd56ms46wnqyrkfs2av853q99a5sec2cckymu7p75za9n7rmvlseuthxl",
    "npub": "npub1yjd56ms46wnqyrkfs2av853q99a5sec2cckymu7p75za9n7rmvlseuthxl",
    "languages": ["en"]
  },
  {
    "name": "Nostr Social Network",
    "twitter": "@NostrSocial",
    "desc": "#nostr npub1azsrmeyn9gerjf767a0z0jsjq29hwt6238txjw47uderwmum4gaq6xkl89",
    "npub": "npub1azsrmeyn9gerjf767a0z0jsjq29hwt6238txjw47uderwmum4gaq6xkl89",
    "languages": ["en"]
  },
  {
    "name": "Jesse Posner",
    "twitter": "@jesseposner",
    "desc": "fnord! #nostr: npub1tr7ndmjkguzupvqhn9clasx4r08q2sklhal4g60844nsnnalw0tqpwnc05",
    "npub": "npub1tr7ndmjkguzupvqhn9clasx4r08q2sklhal4g60844nsnnalw0tqpwnc05",
    "languages": ["en"]
  },
  {
    "name": "Nostr Social Club",
    "twitter": "@NostrClub",
    "desc": "Welcome to the Nostrich Universe  More info coming soon. Account managed by  @Syxdom  #npub12auyyr4hy3l72ymkgkjun7w95e3pl65hjpn0l3auzdz9ad04ew6s67v3cq",
    "npub": "npub12auyyr4hy3l72ymkgkjun7w95e3pl65hjpn0l3auzdz9ad04ew6s67v3cq",
    "languages": ["en"]
  },
  {
    "name": "Joe",
    "twitter": "@latone",
    "desc": "Coal miner. Billionaire. Yogi. #nostr:npub1dqn772m4aejjmnyrjk9c8t4qh3jcquzm2czp48h8pfqh3cgydnds8f2wpa",
    "npub": "npub1dqn772m4aejjmnyrjk9c8t4qh3jcquzm2czp48h8pfqh3cgydnds8f2wpa",
    "languages": ["en"]
  },
  {
    "name": "Anton",
    "twitter": "@AntonLivaja",
    "desc": "Security Engineer | #nostr dev (nostream) | #lightning  | #btc nostr: npub1h72rkut9ljnpdfyrcmw8q9jx52tgn2m8zyg0ehd6z236tz2vmg2sr5k5rt",
    "npub": "npub1h72rkut9ljnpdfyrcmw8q9jx52tgn2m8zyg0ehd6z236tz2vmg2sr5k5rt",
    "languages": ["en"]
  },
  {
    "name": "LazyNinja",
    "twitter": "@FreedomIsntSafe",
    "desc": "Embedded security, #Bitcoin and hardware wallet researcher. #nostr npub1lazy632tc6azc94tmlvyxyjwdt2fg2tu7sj04q0mqcz2ynhp3r3qpnqgcx",
    "npub": "npub1lazy632tc6azc94tmlvyxyjwdt2fg2tu7sj04q0mqcz2ynhp3r3qpnqgcx",
    "languages": ["en"]
  },
  {
    "name": "Zeeland",
    "twitter": "@mhorck",
    "desc": "Bitcoin. Decentralization. Citizens Assemblies. Nostr = Freedom of Speech. Nostr Verification: npub1j9jhvm058mdn9ratsq5uh0u6p53al72qn6y6en9n4klkhdhfjayswc09ha",
    "npub": "npub1j9jhvm058mdn9ratsq5uh0u6p53al72qn6y6en9n4klkhdhfjayswc09ha",
    "languages": ["en"]
  },
  {
    "name": "justlenasart",
    "twitter": "@justlenasart",
    "desc": "LENA | #bitcoin Art | wear your statement  | nostr: npub1e8rmpmqe4ua6uvvhe5wkrcjrgx7ksfehsuqg4gxgeac7uhpyk3kqspjr9k",
    "npub": "npub1e8rmpmqe4ua6uvvhe5wkrcjrgx7ksfehsuqg4gxgeac7uhpyk3kqspjr9k",
    "languages": ["en"]
  },
  {
    "name": "bitcoinplaza",
    "twitter": "@Bitcoin_Plaza",
    "desc": "my bio changes every ten minutes and decreases in characters every four years #nostr npub150zju8xdv9dhdxupzvk0ve6pqu3tmfdrul0mljzkwr3aj4hv40kqmz8dll",
    "npub": "npub150zju8xdv9dhdxupzvk0ve6pqu3tmfdrul0mljzkwr3aj4hv40kqmz8dll",
    "languages": ["en"]
  },
  {
    "name": "Matt",
    "twitter": "@mattsellars",
    "desc": "On the divide • NY Times Best Sellars • he/him • Nostr: npub1npgf8g3hmhf4y8qlu7hk7j3p89hrnheg7nnxzuf9k0zupt44h50sujp4sh",
    "npub": "npub1npgf8g3hmhf4y8qlu7hk7j3p89hrnheg7nnxzuf9k0zupt44h50sujp4sh",
    "languages": ["en"]
  },
  {
    "name": "Nick Giambruno",
    "twitter": "@NickGiambruno",
    "desc": "Contrarian Speculator | Geopolitical Analyst | Investment Professional #nostr #npub1wgjh30uxvvepumxhl3qlfzmt4chueprxmcvrxyw0wjtv9m4w8zwq3se082",
    "npub": "npub1wgjh30uxvvepumxhl3qlfzmt4chueprxmcvrxyw0wjtv9m4w8zwq3se082",
    "languages": ["en"]
  },
  {
    "name": "Luca Brivio è su Mastodon e #nostr",
    "twitter": "@lucabrivio",
    "desc": "Sono lucabrivio@mastodon.social e npub1d33ve5cwzvjfgj333e0hlc57fej35jlwjz6tpyejj72dl4xv252qrgwnfl",
    "npub": "npub1d33ve5cwzvjfgj333e0hlc57fej35jlwjz6tpyejj72dl4xv252qrgwnfl",
    "languages": ["en"]
  },
  {
    "name": "Chad Redfern",
    "twitter": "@ChadMRedfern",
    "desc": "l #Bitcoin Maximalist l #LightningNetwork l Nostr: npub1sf8n6hdtxy9av4alc7tck4xm9874skxl6hrh8td7fsqslvjxqvzss0tqj7",
    "npub": "npub1sf8n6hdtxy9av4alc7tck4xm9874skxl6hrh8td7fsqslvjxqvzss0tqj7",
    "languages": ["en"]
  },
  {
    "name": "SatoshiSound  BITCOIN IS LIBERATION TECH ",
    "twitter": "@SatoshiSound",
    "desc": "#Bitcoin Maximalist |  |  |   Developer  Producer  @btcandfriends  Nostr: npub1sy70twa0vadtk8hjs6wt2hmfszduj04tw78ccs3ktmr9u99mfmqsj62srx",
    "npub": "npub1sy70twa0vadtk8hjs6wt2hmfszduj04tw78ccs3ktmr9u99mfmqsj62srx",
    "languages": ["en"]
  },
  {
    "name": "stefanwouldgo",
    "twitter": "@stefanwouldgo",
    "desc": "@HonigdachsPod  co-host. Making #Bitcoin green today  @netposmon . Find me on nostr: npub1cear2n95zcyze86s5hry2a0pdgs7euhnc0p7ewcq2284pp845t5szt8rhr",
    "npub": "npub1cear2n95zcyze86s5hry2a0pdgs7euhnc0p7ewcq2284pp845t5szt8rhr",
    "languages": ["en"]
  },
  {
    "name": "Seha Islam",
    "twitter": "@sehaislam",
    "desc": "#bitcoin | #nostr |  @EY_US  consulting | views mine #npub1arrnzuwvkqu7vmajtteqvym35raasydp6wxnfckwaugn9kfmjl8sf3fe5c",
    "npub": "npub1arrnzuwvkqu7vmajtteqvym35raasydp6wxnfckwaugn9kfmjl8sf3fe5c",
    "languages": ["en"]
  },
  {
    "name": "John Miles",
    "twitter": "@imjmiles",
    "desc": "Building a global #Bitcoin standard |  @OpenNode  | #nostr: npub1yh0xv855lr9xpx9wdjkvxh73vdxqj8turtpfyn5nt779avkxllkqe6mw80",
    "npub": "npub1yh0xv855lr9xpx9wdjkvxh73vdxqj8turtpfyn5nt779avkxllkqe6mw80",
    "languages": ["en"]
  },
  {
    "name": "am",
    "twitter": "@alokemajumder",
    "desc": "Building  @hoichoitech  ,mostly. Founded  @privacyprio  nostr: #npub1ywxm0f4u85atc7kdm65m7zhvr642ulnsm86m4lfxejsknq4xqfzqcw6xaw",
    "npub": "npub1ywxm0f4u85atc7kdm65m7zhvr642ulnsm86m4lfxejsknq4xqfzqcw6xaw",
    "languages": ["en"]
  },
  {
    "name": "Unit of a Count",
    "twitter": "@CountBitcoin",
    "desc": "I run on Bitcoin Standard Time Nostr: npub1mdgx4zvhlhwu5lyz7qpk3auehgzaf6frlcshur0zdee0hta3sa3slpj2aj countbitcoin@getalby.com",
    "npub": "npub1mdgx4zvhlhwu5lyz7qpk3auehgzaf6frlcshur0zdee0hta3sa3slpj2aj",
    "languages": ["en"]
  },
  {
    "name": "Tonewrecker",
    "twitter": "@thetonewrecker",
    "desc": "Communication through machines+odd dreams. Audio Explorer #Nostr npub1xa3dx9vmlkwc4j6kvalwexn03fdqt65xvdscdjnw6ec556vhtlksr0sas6 tonewreckertracks@getalby.com",
    "npub": "npub1xa3dx9vmlkwc4j6kvalwexn03fdqt65xvdscdjnw6ec556vhtlksr0sas6",
    "languages": ["en"]
  },
  {
    "name": "Martti Malmi",
    "twitter": "@marttimalmi",
    "desc": "Bitcoin dev in 2009-2011. A.K.A. Sirius developing http://iris.to #nostr npub1g53mukxnjkcmr94fhryzkqutdz2ukq4ks0gvy5af25rgmwsl4ngq43drvk",
    "npub": "npub1g53mukxnjkcmr94fhryzkqutdz2ukq4ks0gvy5af25rgmwsl4ngq43drvk",
    "languages": ["en"]
  },
  {
    "name": "Yegor Petrov ",
    "twitter": "@yeg0rpetrov",
    "desc": "#Bitcoin| Community Manager at  @BitcoinMag_UA , Central Asia  @BitcoinMag_CCA  | #nostr: #npub1z4m7gkva6yxgvdyclc7zp0vz4ta0s2d9jh8g83w03tp5vdf3kzdsxana6p",
    "npub": "npub1z4m7gkva6yxgvdyclc7zp0vz4ta0s2d9jh8g83w03tp5vdf3kzdsxana6p",
    "languages": ["en"]
  },
  {
    "name": "Bitcompare",
    "twitter": "@bitcomparenet",
    "desc": "Maximise your Bitcoin and Crypto wealth Follow us on Nostr npub1n5knsxvvh2jldmmcer2wjq7h4tfxq084x9ymx5tpvjexyvduz23sv08x58",
    "npub": "npub1n5knsxvvh2jldmmcer2wjq7h4tfxq084x9ymx5tpvjexyvduz23sv08x58",
    "languages": ["en"]
  },
  {
    "name": "Nitesh ₿",
    "twitter": "@nitesh_btc",
    "desc": "Open Source Dev | #Bitcoin | Man United Fan | Lightning Engineer  @FoundryServices  | #nostr: npub1qgwhaaa2lsp54rl0hfx7qa3z678ax6wlre0em475rhpvl7n54cpqgg7y7n",
    "npub": "npub1qgwhaaa2lsp54rl0hfx7qa3z678ax6wlre0em475rhpvl7n54cpqgg7y7n",
    "languages": ["en"]
  },
  {
    "name": "W₿ Moone ",
    "twitter": "@WendellMoone",
    "desc": "#Bitcoin #bitcoin #BTC #btc 13%er   nostr: npub1cdsjv7mw62vhzvfpswj4rkfqjk4eq8lasgsnk8fzp2sdlqlcxt9se4eeke",
    "npub": "npub1cdsjv7mw62vhzvfpswj4rkfqjk4eq8lasgsnk8fzp2sdlqlcxt9se4eeke",
    "languages": ["en"]
  },
  {
    "name": "ᗪIGITᗩᒪᐯETEᖇᗩᑎ",
    "twitter": "@DigitalVeteran_",
    "desc": " #Browns  #btc  #eth  HODL  #nostr: npub1uywf73d6dsqdwazspz04m2mjmkzq3ht3x6s46dr9epextd9nnjtqxv0lgv",
    "npub": "npub1uywf73d6dsqdwazspz04m2mjmkzq3ht3x6s46dr9epextd9nnjtqxv0lgv",
    "languages": ["en"]
  },
  {
    "name": "MetaMick ",
    "twitter": "@metamick14",
    "desc": "Interested in human problems. Building  @geyserfund  Nostr: npub13v9zh6hka0heyh5w0ru0ptdyrauf3wx6w25fwxufnz9l0ptax60sg63rka",
    "npub": "npub13v9zh6hka0heyh5w0ru0ptdyrauf3wx6w25fwxufnz9l0ptax60sg63rka",
    "languages": ["en"]
  },
  {
    "name": "Jim₿Logic ∞/21M",
    "twitter": "@JimBLogic",
    "desc": "Bitcoiner, Running  @RaspiBlitz  full node with Lightning| Twitch Partner Streamer | #Nostr npub1xadgv77c979w4gy6plq3tw3lmklmthreukupy436n0yv3q6prvkqcgdkt2",
    "npub": "npub1xadgv77c979w4gy6plq3tw3lmklmthreukupy436n0yv3q6prvkqcgdkt2",
    "languages": ["en"]
  },
  {
    "name": "Tony Sly ",
    "twitter": "@N0US3F0R4N4M3",
    "desc": "Left for nostr: npub146j2myq28t6nw87e0v89q0tq47wcmsvkpdxszc5wkyqtgfsvuh7q8k2jsd",
    "npub": "npub146j2myq28t6nw87e0v89q0tq47wcmsvkpdxszc5wkyqtgfsvuh7q8k2jsd",
    "languages": ["en"]
  },
  {
    "name": "kidproto  ",
    "twitter": "@kidproto",
    "desc": "#nostr http://tinyurl.com/2jade63f npub1matmfxr293jejewrm72u50l2x5e6ypasn0ev2kns6srv05zfzf8s0z6fsr",
    "npub": "npub1matmfxr293jejewrm72u50l2x5e6ypasn0ev2kns6srv05zfzf8s0z6fsr",
    "languages": ["en"]
  },
  {
    "name": "Nate.FutureHomesteader",
    "twitter": "@nk1tz",
    "desc": "Engineering at  @unchainedcap . Formerly  @bullbitcoin_  . Advisor  @zaprite . nostr: npub19vm0k6hpqgks6n4v92038lpx8ren2zkvnvrmmjsausa8cc6zjezqj474vg ",
    "npub": "npub19vm0k6hpqgks6n4v92038lpx8ren2zkvnvrmmjsausa8cc6zjezqj474vg",
    "languages": ["en"]
  },
  {
    "name": "Farooq Ahmed",
    "twitter": "@FarooqAhmedX",
    "desc": "#bitcoin #nostr: npub1rqe7upz9nl4jef9wdyx47vfxnt2g3357tl5s8fpt2vkxwlz2s9cq9w3jdt",
    "npub": "npub1rqe7upz9nl4jef9wdyx47vfxnt2g3357tl5s8fpt2vkxwlz2s9cq9w3jdt",
    "languages": ["en"]
  },
  {
    "name": "WARTIME PSYCHOPATH™ ",
    "twitter": "@Big_Vision_GR",
    "desc": "#Bitcoin only. Toxic. Nostr ID: (delete the # sign in front) #npub1mkzqusemj7retwe45sytcc0006vk3zaqk9n03j6v0n4uk5ccajcq9nkq7q http://tippin.me/@Big_Vision_GR §BVC",
    "npub": "npub1mkzqusemj7retwe45sytcc0006vk3zaqk9n03j6v0n4uk5ccajcq9nkq7q",
    "languages": ["en"]
  },
  {
    "name": "fixtheworld ",
    "twitter": "@f1xtheworld",
    "desc": "#bitcoin - fix the money - fix the world. Anti: #WEF, #WHO, #IMF, #Seedoils, #FIAT #nostr: npub1ym9vzeslx2qnqhhjhetrpv0s3nt4fnjeq67zsj3px8jgvgh8cksss6l2j8",
    "npub": "npub1ym9vzeslx2qnqhhjhetrpv0s3nt4fnjeq67zsj3px8jgvgh8cksss6l2j8",
    "languages": ["en"]
  },
  {
    "name": "GhostBTCBLD",
    "twitter": "@GhostBTCBLD",
    "desc": "memes, write articles, bitcoin maxi NOSTR: npub1042g2sfx7hj94jvd8a9vmh5y8eyjqa9nm939gl7r0g4pynwp7v7sh6ctmn",
    "npub": "npub1042g2sfx7hj94jvd8a9vmh5y8eyjqa9nm939gl7r0g4pynwp7v7sh6ctmn",
    "languages": ["en"]
  },
  {
    "name": "Stevie Zollo",
    "twitter": "@StevieZollo",
    "desc": "Building infrastructure  @Strike . #Bitcoin is Renaissance. Acta non verba. #Nostr: npub100n0yahp4kan33pz3htw5e5qegs3t5umesesjp9ycz3l4xpwawws988nqc",
    "npub": "npub100n0yahp4kan33pz3htw5e5qegs3t5umesesjp9ycz3l4xpwawws988nqc",
    "languages": ["en"]
  },
  {
    "name": "𝙎𝙒𝙀𝙇𝙇 ₿ 丰 ",
    "twitter": "@SatoshiWasRight",
    "desc": "Creator of Bitcoin - Nostr: npub1cvvelgfelvvk6w65yls98pdkgd96zrg035g5mxttspspgjm6pqls360w68",
    "npub": "npub1cvvelgfelvvk6w65yls98pdkgd96zrg035g5mxttspspgjm6pqls360w68",
    "languages": ["en"]
  },
  {
    "name": "Mike Reyes",
    "twitter": "@mikereyes",
    "desc": "Associate Producer  @wbd  • poodle dad • ‍ • powered by yoga and coffee | #bitcoin #nostr npub1r49cz9mzk9ru8gceys8gh2pgayrkyckm2aus76x66ept0zrw090sw687r0",
    "npub": "npub1r49cz9mzk9ru8gceys8gh2pgayrkyckm2aus76x66ept0zrw090sw687r0",
    "languages": ["en"]
  },
  {
    "name": "Richard Safier - HelloJessica Node",
    "twitter": "@NodeHello",
    "desc": "nodehello@safier.com R&D Lightning Ops Engineer  @zebedeeio  http://plebnet.fun / http://plebnet.wiki nostr: npub1k9tkawv6ga6ptz3jl30pjzh68hk5mgvl28al5zc6r0myy849wvaq38a70g",
    "npub": "npub1k9tkawv6ga6ptz3jl30pjzh68hk5mgvl28al5zc6r0myy849wvaq38a70g",
    "languages": ["en"]
  },
  {
    "name": "Mauricio",
    "twitter": "@PetoVeritas",
    "desc": "Defeat Authoritarians. Defend truth. #bitcoin #nostr: npub10htqtrkhcztsel8e3zka259npr4s2gx8mfaj676hvmu9vt0c7hyqu0smgf",
    "npub": "npub10htqtrkhcztsel8e3zka259npr4s2gx8mfaj676hvmu9vt0c7hyqu0smgf",
    "languages": ["en"]
  },
  {
    "name": "João Bordalo ",
    "twitter": "@bordalix",
    "desc": "Don't trust, verify. #nostr: npub1vt803quxxq32fuwkp42g2lyaw2t9qupvnl3z0vyc3s9kudkyhn8qt28cxv bordalix@getalby.com",
    "npub": "npub1vt803quxxq32fuwkp42g2lyaw2t9qupvnl3z0vyc3s9kudkyhn8qt28cxv",
    "languages": ["en"]
  },
  {
    "name": "Valk ₿ ",
    "twitter": "@wj_valk",
    "desc": "#BTC | Former marketing and design | #Nostr npub1et6zwlcv5untje8znh8thg3av0668l0s937naujng356u4lnj63qkfp3g5 http://getalby.com/p/vandervalk",
    "npub": "npub1et6zwlcv5untje8znh8thg3av0668l0s937naujng356u4lnj63qkfp3g5",
    "languages": ["en"]
  },
  {
    "name": "California HODL",
    "twitter": "@california_hodl",
    "desc": "Stacking Sats | Runner of Nodes | #bitcoin maxi | Twitter #btc Nostr: npub1hquy7xlxrsahpkpu90tpwfy9whcd0lc4pg9c7cqs Bot Author  @Dollar2Satoshis ,  @aLtErNaTePeTeR",
    "npub": "npub1hquy7xlxrsahpkpu90tpwfy9whcd0lc4pg9c7cqs",
    "languages": ["en"]
  },
  {
    "name": "Ricardo Reis",
    "twitter": "@ricardoreis007",
    "desc": "#Bitcoin: ricardo@zbd.gg #Nostr: npub185l4u8306dv35t0mec8au0c9jah9u6j3vkv7yrsqmyfm8ruvguhqyv8yw6 PGP: 83E1 3FD2 91BE BB5E 81E0 B0FF 21AC FD5F 89FB AC2A",
    "npub": "npub185l4u8306dv35t0mec8au0c9jah9u6j3vkv7yrsqmyfm8ruvguhqyv8yw6",
    "languages": ["en"]
  },
  {
    "name": "Nick Talwar",
    "twitter": "@talweezy",
    "desc": "Eng leader, climate tech, eng mgmt book writing, design, love,  @DukeU  alum nostr npub1fjux8jwh09ty3q5v2d3jkfnyfgj2eapu8cja9tslxr4n9ml5zcaqd98k8q",
    "npub": "npub1fjux8jwh09ty3q5v2d3jkfnyfgj2eapu8cja9tslxr4n9ml5zcaqd98k8q",
    "languages": ["en"]
  },
  {
    "name": "Ryan Finlay",
    "twitter": "@ryanfinlay",
    "desc": "Dad. Working on tech projects with my kids. #Bitcoin nostr:#npub1w9fty2lc7szxphqm78rhwvtal8pj6xt5mukdmdcpyg6tnf9ksfwquqzy6t",
    "npub": "npub1w9fty2lc7szxphqm78rhwvtal8pj6xt5mukdmdcpyg6tnf9ksfwquqzy6t",
    "languages": ["en"]
  },
  {
    "name": "NEO-BTC ",
    "twitter": "@GigaBTC",
    "desc": "#Bitcoin Miner. Sovereign Individual. Follow the white rabbit. #Nostr / #Damus : npub1f5qxsvu27hh8nsr9z0024upyjgj8h0m6h55lz9hxu5xptz4k5emsed576p",
    "npub": "npub1f5qxsvu27hh8nsr9z0024upyjgj8h0m6h55lz9hxu5xptz4k5emsed576p",
    "languages": ["en"]
  },
  {
    "name": "AnarchoCanadian",
    "twitter": "@AnarchoCanadian",
    "desc": "http://runningbitco.in #Bitcoin #nostr: npub120ywrjetvrreel9tz6dayvyh2h6zuw40zzyshsuls4qvq4m94t0sk5snzd ",
    "npub": "npub120ywrjetvrreel9tz6dayvyh2h6zuw40zzyshsuls4qvq4m94t0sk5snzd",
    "languages": ["en"]
  },
  {
    "name": "𝗣𝗔𝗕𝗟𝗢",
    "twitter": "@paul",
    "desc": "figuring out dinner ¯\\\\_(ツ)_/¯ | seo |   | he/him #nostr: npub15nkxxz9fcw57t6mdq2ky6l4xguq799x9nqqs68w4u5ag8z4wr04qlzujde",
    "npub": "npub15nkxxz9fcw57t6mdq2ky6l4xguq799x9nqqs68w4u5ag8z4wr04qlzujde",
    "languages": ["en"]
  },
  {
    "name": "Matt ₿lack  13% Bitcoiner",
    "twitter": "@matthewjablack",
    "desc": "CoFounder  @AtomicFinance . Boomer Millenial. #Bitcoin not crypto. Nostr not blockchain npub1p2mljv5hxskv3mp5qwsmevad97nwg7r94rs5cwh62qwgqylunp8s5lpguz",
    "npub": "npub1p2mljv5hxskv3mp5qwsmevad97nwg7r94rs5cwh62qwgqylunp8s5lpguz",
    "languages": ["en"]
  },
  {
    "name": "KANSAS HODL",
    "twitter": "@BubblesBitcoin",
    "desc": "Bitcoin only | Pleb | Cyber-Hornet | Security engineer Nostr: npub1j4lrcg98044ulm5593j6l9qyk4hu79jlfrgrwvk3h9kqaruuuwgs4x9gs2",
    "npub": "npub1j4lrcg98044ulm5593j6l9qyk4hu79jlfrgrwvk3h9kqaruuuwgs4x9gs2",
    "languages": ["en"]
  },
  {
    "name": "Jacob",
    "twitter": "@ismyhc",
    "desc": "Husband | Father of 3 | Founder and CEO at Galaxoid Labs,  frisson junky  #nostr: npub1ch8a4xxsru2jkdynmx27a4xdknv72k5h8yjld702y3mf5k3puauqmszh48 http://ln.games",
    "npub": "npub1ch8a4xxsru2jkdynmx27a4xdknv72k5h8yjld702y3mf5k3puauqmszh48",
    "languages": ["en"]
  },
  {
    "name": "Cøøṟ",
    "twitter": "@Cocopoppyhead",
    "desc": "SEO Pro stackin sats, awfully fond of coffee, wine & whiskey   @bitcoinmagazine  #Nostr: npub1x87vs7f453mc2h4mnfqj844hj644gy3zapy42mm3gxl3syanjr6spfxkuj",
    "npub": "npub1x87vs7f453mc2h4mnfqj844hj644gy3zapy42mm3gxl3syanjr6spfxkuj",
    "languages": ["en"]
  },
  {
    "name": "Rick Stringer",
    "twitter": "@rick_stringer",
    "desc": "Owner: Rick Stringer Creative Services, LLC nostr: npub1afvu8hx9wv8zkvxawcx36kc2n5wpf4qwhjfk02rgl22n7pj2098qw6d8hh",
    "npub": "npub1afvu8hx9wv8zkvxawcx36kc2n5wpf4qwhjfk02rgl22n7pj2098qw6d8hh",
    "languages": ["en"]
  },
  {
    "name": "Oscar Pacey",
    "twitter": "@oscpacey",
    "desc": "Nostr: npub1fam4gsxke9up7mqrp4sx50w5jesd9mm7ddvfljnhs0en7h924t7s2gazhv",
    "npub": "npub1fam4gsxke9up7mqrp4sx50w5jesd9mm7ddvfljnhs0en7h924t7s2gazhv",
    "languages": ["en"]
  },
  {
    "name": "Ken Charles ∞/21M",
    "twitter": "@CryptoKWC",
    "desc": "Software Developer. #Bitcoin only. #nostr npub1rp7tqfx6qw2ljcg6jac3hme2h4uynqf77ftfpnvyrvu7lh0wsjzsar8gy3",
    "npub": "npub1rp7tqfx6qw2ljcg6jac3hme2h4uynqf77ftfpnvyrvu7lh0wsjzsar8gy3",
    "languages": ["en"]
  },
  {
    "name": "₿ Isaiah",
    "twitter": "@BitcoinIsaiah",
    "desc": "#Bitcoin class of 2017. Professional stacker of sats. nostr: npub1xyfe7g8se4sgs7gfjpelatkfdjhznr6xqydxsxe83n5vmelqcftsmlhhgn",
    "npub": "npub1xyfe7g8se4sgs7gfjpelatkfdjhznr6xqydxsxe83n5vmelqcftsmlhhgn",
    "languages": ["en"]
  },
  {
    "name": "Dr. Monali Y. Desai, M.D.",
    "twitter": "@drmonalidesai",
    "desc": "Cardiologist (locum tenens), Consulting in health tech, #nostr: npub16cfnxee9vpp2vamnrpj4hu0n3kxdm9z0fw4xczmsaa9sykv54uzqgu8gmf",
    "npub": "npub16cfnxee9vpp2vamnrpj4hu0n3kxdm9z0fw4xczmsaa9sykv54uzqgu8gmf",
    "languages": ["en"]
  },
  {
    "name": "Tatum Turn Up",
    "twitter": "@tatumturnup",
    "desc": "Host of #BetweenTwoASICs. Master of the corn. #Nostr #npub1hte85nxymfyez0nlmmxf287nh9cujfuetxhk9vptwcdqg0pn8pxqxasw3d Contact: tatum@betweentwoasics.com",
    "npub": "npub1hte85nxymfyez0nlmmxf287nh9cujfuetxhk9vptwcdqg0pn8pxqxasw3d",
    "languages": ["en"]
  },
  {
    "name": "Avid",
    "twitter": "@AvidBitcoin",
    "desc": "Pragmatist | All models are wrong, some are useful | Nostr pubkey: #npub1k7vkcxp7qdkly7qzj3dcpw7u3v9lt9cmvcs6s6ln26wrxggh7p7su3c04l",
    "npub": "npub1k7vkcxp7qdkly7qzj3dcpw7u3v9lt9cmvcs6s6ln26wrxggh7p7su3c04l",
    "languages": ["en"]
  },
  {
    "name": "₿en Weeks ",
    "twitter": "@BenGWeeks",
    "desc": "Digital Strategist | Author | Digital Transformations | Bitcoin Advocate #nostr: #npub1jutptdc2m8kgjmudtws095qk2tcale0eemvp4j2xnjnl4nh6669slrf04x",
    "npub": "npub1jutptdc2m8kgjmudtws095qk2tcale0eemvp4j2xnjnl4nh6669slrf04x",
    "languages": ["en"]
  },
  {
    "name": "Yannick",
    "twitter": "@osteel",
    "desc": "Backend development, Bitcoin, and everything in between. Co-organiser of  @PHPSussexUG . I tweet in French once in a while. Nostr: npub1h9hwa8fn4pljhdwan6uvhe0pm",
    "npub": "npub1h9hwa8fn4pljhdwan6uvhe0pm",
    "languages": ["en"]
  },
  {
    "name": "MAC ₿",
    "twitter": "@MacB10101",
    "desc": "#bitcoin, $sats, #nostr npub12rne64twevx224gmzglur3lg9nj5r7n9v7ld9syauevz73a9en3shswfg9",
    "npub": "npub12rne64twevx224gmzglur3lg9nj5r7n9v7ld9syauevz73a9en3shswfg9",
    "languages": ["en"]
  },
  {
    "name": "Samuel A. Stacksome",
    "twitter": "@samusalo",
    "desc": "#Bitcoin permabull   @BraiinsMining  home miner  intermittent memer  meme enjoyooor  Nostr: npub1vtv08t8frht59gplamngklvt9jdj5haa64zcydysh8mu4ucw32msljpte",
    "npub": "npub1vtv08t8frht59gplamngklvt9jdj5haa64zcydysh8mu4ucw32msljpte",
    "languages": ["en"]
  },
  {
    "name": "ScotiaHodl",
    "twitter": "@ScotiaHodl",
    "desc": "Financially illiterate, shadowy psychopath. #bitcoin #StackChain #268 Nostr npub1fk4du7tdrupfjq6z3estsz8nshlj8n5kemwqrl57j9vvgst7mr2qrmtvzt",
    "npub": "npub1fk4du7tdrupfjq6z3estsz8nshlj8n5kemwqrl57j9vvgst7mr2qrmtvzt",
    "languages": ["en"]
  },
  {
    "name": "Christopher David ",
    "twitter": "@ArcadeCityMayor",
    "desc": "Stacking #bitcoin and hacking  @TheArcApp  &  @FaerieAI . #nostr: npub1tlv67m7xvlyplzexuynmfpguvyet0sjffce3y8vu0suuyuwgzauqjk7fdm",
    "npub": "npub1tlv67m7xvlyplzexuynmfpguvyet0sjffce3y8vu0suuyuwgzauqjk7fdm",
    "languages": ["en"]
  },
  {
    "name": "man with no name",
    "twitter": "@ihodlon",
    "desc": "#Bitcoin or nothing. #nostr npub1m53zg3zvgxzg8lyytnnxqzz7pa0c8ct2k7q93wkcrns5acejjcdqffqnmz",
    "npub": "npub1m53zg3zvgxzg8lyytnnxqzz7pa0c8ct2k7q93wkcrns5acejjcdqffqnmz",
    "languages": ["en"]
  },
  {
    "name": "Loly super-coder   ",
    "twitter": "@LolyEchairi",
    "desc": "#bitcoin #nostr: npub1ylzdwad7m7hcv9zjavmxuhdnmx2havk55gnvmzzkm40gxas2hjhq2k30xq",
    "npub": "npub1ylzdwad7m7hcv9zjavmxuhdnmx2havk55gnvmzzkm40gxas2hjhq2k30xq",
    "languages": ["en"]
  },
  {
    "name": "Jay Beddict",
    "twitter": "@JBeddict",
    "desc": "#bitcoin, hashing, hardware, user control, . Your keys, your coins; my words, my views. nostr: npub1wec4sj8tymnkag3gfr0zuufm3xmr857txhhfkkhm697lqkrstnyqe35",
    "npub": "npub1wec4sj8tymnkag3gfr0zuufm3xmr857txhhfkkhm697lqkrstnyqe35",
    "languages": ["en"]
  },
  {
    "name": "Nicola ",
    "twitter": "@nclcocco",
    "desc": "Nostr: npub1xrja8ekfnzvecwds4zqzf7zlqlervty7wkyl055t8x0jn93f0rds7xrhk7",
    "npub": "npub1xrja8ekfnzvecwds4zqzf7zlqlervty7wkyl055t8x0jn93f0rds7xrhk7",
    "languages": ["en"]
  },
  {
    "name": "Sonoran",
    "twitter": "@looneymd",
    "desc": "Derivatives and algo trader. Crypto boomer. WallStreetBets dApp enthusiast. Nostr npub1jq70xtj70gd326xep37eq7py60etl67fgfkua5urndwgkvltp77qjuycqf",
    "npub": "npub1jq70xtj70gd326xep37eq7py60etl67fgfkua5urndwgkvltp77qjuycqf",
    "languages": ["en"]
  },
  {
    "name": "Ankur Tyagi",
    "twitter": "@7h3rAm",
    "desc": "।। शापादपि  शरादपि  ।। #nostr npub1e43jtn2ht67nc7jhd3p6r46q0d0k2d80h3gxvh4sdh4cmdp3yztq4ctsma",
    "npub": "npub1e43jtn2ht67nc7jhd3p6r46q0d0k2d80h3gxvh4sdh4cmdp3yztq4ctsma",
    "languages": ["en"]
  },
  {
    "name": "Jukka",
    "twitter": "@JukkaBlomberg",
    "desc": "#Bitcoin #nostr: npub1d9ju3avd695v4uclk2qe8q87wrmt23cax62tlx8dv4lcsapteqlsy5tmd4",
    "npub": "npub1d9ju3avd695v4uclk2qe8q87wrmt23cax62tlx8dv4lcsapteqlsy5tmd4",
    "languages": ["en"]
  },
  {
    "name": "ᴄᴏʟin ꜱᴜʟʟɪᴠᴀɴ",
    "twitter": "@Colin_Sully",
    "desc": "CEO  @MintGreenHQ   | #Bitcoin OG | nostr: #npub1gl723nmngd9adxh8hqgyrpnam8ha8m8pu04vfakr6g8k9e4y3m4s7ukd90",
    "npub": "npub1gl723nmngd9adxh8hqgyrpnam8ha8m8pu04vfakr6g8k9e4y3m4s7ukd90",
    "languages": ["en"]
  },
  {
    "name": "Popstar.lens",
    "twitter": "@MJthePopstar",
    "desc": "King of Pop-ping bubbles Senior idea man with chronic pain nostr: npub15qvvav2eehu897rdxxh6t9c80qyjz4ehpjpacd9vzga0chxaxy6q85wggu",
    "npub": "npub15qvvav2eehu897rdxxh6t9c80qyjz4ehpjpacd9vzga0chxaxy6q85wggu",
    "languages": ["en"]
  },
  {
    "name": "Ahmad Alkabra ",
    "twitter": "@ahmadalkabra",
    "desc": "Building on #Bitcoin. I take sports  and espresso  very seriously. Nostr PubKey: npub1us2dmrmxf3t6nzrmnum2gsvqecca6qhmxewzljcck59u25khpzhqnks8mm",
    "npub": "npub1us2dmrmxf3t6nzrmnum2gsvqecca6qhmxewzljcck59u25khpzhqnks8mm",
    "languages": ["en"]
  },
  {
    "name": "Renzo.hodl ∞/21M",
    "twitter": "@narandom20",
    "desc": "Family always - Bitcoin only - Jiu Jitsu daily. hodlbitcoin at http://keybase.io. Nostr: npub1rak8dhd6kgfum4pakf54k9r5vptrnp3rqtrult34xc473jhth8ysvu0tta",
    "npub": "npub1rak8dhd6kgfum4pakf54k9r5vptrnp3rqtrult34xc473jhth8ysvu0tta",
    "languages": ["en"]
  },
  {
    "name": "Nathan Levy, druggist",
    "twitter": "@NathanLevy10",
    "desc": "Follow me or don’t I do not care. Autodidact with lots of meaningless degrees. Nostr: npub12xjv46jwjlvkx09dd6v2ek8vshuycjqyw05hrz0a22pd4693zjvqrduxdm",
    "npub": "npub12xjv46jwjlvkx09dd6v2ek8vshuycjqyw05hrz0a22pd4693zjvqrduxdm",
    "languages": ["en"]
  },
  {
    "name": "Listen very carefully, I shall say this only once!",
    "twitter": "@MixedPie",
    "desc": "Son of an immigr ant ⓘ $erif© Forbes self made billionaire 2025. #nostr: npub1h8xuyzvnxa0qmg68x09fwm62g040xy9h62eu9sdeam48a45fupfq2xfe5l",
    "npub": "npub1h8xuyzvnxa0qmg68x09fwm62g040xy9h62eu9sdeam48a45fupfq2xfe5l",
    "languages": ["en"]
  },
  {
    "name": "MT",
    "twitter": "@tmknsm",
    "desc": "#Bitcoin · Product Designer  @Blocks  ·  @plasmasocial  #nostr npub1nqc4zvkk4wx0usz082qydwpnd42979y5k93mdmn2vwguttkzfrysqjy5lh",
    "npub": "npub1nqc4zvkk4wx0usz082qydwpnd42979y5k93mdmn2vwguttkzfrysqjy5lh",
    "languages": ["en"]
  },
  {
    "name": "Derrick Schippert",
    "twitter": "@derrrick",
    "desc": "product designer  @blocks  #nostr: npub1cverkzgrjxqzangz3r0zd3s9srxeql2mkvw3m7snyxlyrpr78e0smqa5qv",
    "npub": "npub1cverkzgrjxqzangz3r0zd3s9srxeql2mkvw3m7snyxlyrpr78e0smqa5qv",
    "languages": ["en"]
  },
  {
    "name": "C4R3Bear ",
    "twitter": "@C4R3Bear",
    "desc": "#bitcoin. lightning. timechainist. #lntrustchain2 Nostr Pubkey: npub1qxvqvg9guzslwlrkzg3s5fdry5ccddy8xdnvzrsck7jx0cnuwlss50u8k4",
    "npub": "npub1qxvqvg9guzslwlrkzg3s5fdry5ccddy8xdnvzrsck7jx0cnuwlss50u8k4",
    "languages": ["en"]
  },
  {
    "name": "Austin ",
    "twitter": "@acwilcox",
    "desc": "You can’t stop the waves but you can learn how to surf. #Bitcoin nostr: npub13ka47kkmlt222tmzuuvknahnhxztw9r4je8syl55lef6t6nvdg4sndu26q",
    "npub": "npub13ka47kkmlt222tmzuuvknahnhxztw9r4je8syl55lef6t6nvdg4sndu26q",
    "languages": ["en"]
  },
  {
    "name": "Johny ",
    "twitter": "@johnyduval",
    "desc": "Open. Distributed. Ownership. #nostr: npub1hxskpr2269jvky26r4q07dh06y4e8syhe54rh7p2trpj2dzg3zfsjfzj7y",
    "npub": "npub1hxskpr2269jvky26r4q07dh06y4e8syhe54rh7p2trpj2dzg3zfsjfzj7y",
    "languages": ["en"]
  },
  {
    "name": "@0xtr #FreeAssangeNOW",
    "twitter": "@0xtr1",
    "desc": "Software developer. #bitcoin paynym: +noisywave103 Nostr: npub1ktt8phjnkfmfrsxrgqpztdjuxk3x6psf80xyray0l3c7pyrln49qhkyhz0",
    "npub": "npub1ktt8phjnkfmfrsxrgqpztdjuxk3x6psf80xyray0l3c7pyrln49qhkyhz0",
    "languages": ["en"]
  },
  {
    "name": "Noodle ",
    "twitter": "@NoodleNakamoto",
    "desc": "Husband. Father. #Bitcoin Maxi. Spurs Addict. Make and perform music. Nostr: npub1xpucrv380lxf2lfvx2p4kdk6hzcr6uyxtru7n2jwvk7q3a5wtcls3w5u46",
    "npub": "npub1xpucrv380lxf2lfvx2p4kdk6hzcr6uyxtru7n2jwvk7q3a5wtcls3w5u46",
    "languages": ["en"]
  },
  {
    "name": "Bitcoin Pumpkin",
    "twitter": "@BitcoinPumpkin",
    "desc": "Mined #Bitcoin in 2011. Fascinated by On-Chain Analytics. Semi-retired PUMPkin. Nostr: npub1vdakt92l7uepspscfqh2ph7jns0usga2d0yp5hdu55jyfk2gs8ksxgjnr6",
    "npub": "npub1vdakt92l7uepspscfqh2ph7jns0usga2d0yp5hdu55jyfk2gs8ksxgjnr6",
    "languages": ["en"]
  },
  {
    "name": "kuntah ",
    "twitter": "@paulewaulpaul",
    "desc": "bitcoin - nostr: npub18ct06ka4uxns900rxpzyegkwfenfv75vq6d9e9u885kq6k7knwcqc0yghx",
    "npub": "npub18ct06ka4uxns900rxpzyegkwfenfv75vq6d9e9u885kq6k7knwcqc0yghx",
    "languages": ["en"]
  },
  {
    "name": "José",
    "twitter": "@josebitcoiner",
    "desc": "Founder  @LightningKoffee   #Nostr: #npub1cxtzr09d9jw4qfscm7hjtf47pl0z80tnpegc38wgsvmkeywv5mzqe2d5cj #bitcoin",
    "npub": "npub1cxtzr09d9jw4qfscm7hjtf47pl0z80tnpegc38wgsvmkeywv5mzqe2d5cj",
    "languages": ["en"]
  },
  {
    "name": "Tane ($TIMPI $FLUX $KDA)",
    "twitter": "@rigduud",
    "desc": "$TIMPI $FLUX $KDA #WEB3 #passiveincome #nodes #privacy My #Nostr public key npub1g3xnz0meay73h5wumuqnl663ez3lc74pv4xgym602t6726a2azfq96ypqh http://ido.timpi.io",
    "npub": "npub1g3xnz0meay73h5wumuqnl663ez3lc74pv4xgym602t6726a2azfq96ypqh",
    "languages": ["en"]
  },
  {
    "name": "Anil Wadghule",
    "twitter": "@anildigital",
    "desc": "dev  @ErlangSolutions  | previously  @EqualExperts  | Salads  | #nostr: npub1y76namxxyjnvmphq46asjuclws69zyuk6u9qkljgxczkkw6dda4q52y6l8",
    "npub": "npub1y76namxxyjnvmphq46asjuclws69zyuk6u9qkljgxczkkw6dda4q52y6l8",
    "languages": ["en"]
  },
  {
    "name": "neb_b",
    "twitter": "@neb_b",
    "desc": "#bitcoin - building Daisy - a nostr client for iOS and Android: https://neb.lol/nostr #npub1e0usfsrs9gmpjywyd4un0xn22q4u80gtf3tdy5ufuckna0620kuq5w732v",
    "npub": "npub1e0usfsrs9gmpjywyd4un0xn22q4u80gtf3tdy5ufuckna0620kuq5w732v",
    "languages": ["en"]
  },
  {
    "name": "BITCOIN CORDA",
    "twitter": "@bitcoincorda",
    "desc": "#Bitcoin #SatsArmy Nostr: npub1qa4q9ddc2022n7eg45pt8kw7zsu6uru6cqz2n33kc54el3vmgtmqja3fks",
    "npub": "npub1qa4q9ddc2022n7eg45pt8kw7zsu6uru6cqz2n33kc54el3vmgtmqja3fks",
    "languages": ["en"]
  },
  {
    "name": "₿acon.btc - Pro-Proof of Work.",
    "twitter": "@BaconPDX",
    "desc": "$BTC Majoritarian, Full Node Op. | Don't Trust, Verify | On NOSTR: npub14yg7dxmnmnc34w7gzvgwp2lf9wg8kkj20gy6vzpvfcz8jajkfpfqg434mf",
    "npub": "npub14yg7dxmnmnc34w7gzvgwp2lf9wg8kkj20gy6vzpvfcz8jajkfpfqg434mf",
    "languages": ["en"]
  },
  {
    "name": "Aloisio ",
    "twitter": "@barrigmi",
    "desc": "Macro | Equities | Cryptos | NFTs #nostr: npub1w5379ksst6elvvqj5lp28zqalpzrajq25fy3eldg8rzkeu99qkhssm0mn2",
    "npub": "npub1w5379ksst6elvvqj5lp28zqalpzrajq25fy3eldg8rzkeu99qkhssm0mn2",
    "languages": ["en"]
  },
  {
    "name": "Piccolo ",
    "twitter": "@PlebPiccoloJr",
    "desc": "Freedom loving Namekian key master obsessed with the time chain. Curator of  @BOBSpace_BKK . Nostr npub13lpnsq95lg8yz7nplv094qmzf8c90ackgl7paytnk53d42d0zufshxrx7d",
    "npub": "npub13lpnsq95lg8yz7nplv094qmzf8c90ackgl7paytnk53d42d0zufshxrx7d",
    "languages": ["en"]
  },
  {
    "name": "coinOmania",
    "twitter": "@OmaniaCoin",
    "desc": "Nostr-A-Damus (copy it easily but no hash tag) #npub162r20vj0kpge43zkmz63emn5qpg06apuf0sg8dftlh5arpqyrhfq3d37ca",
    "npub": "npub162r20vj0kpge43zkmz63emn5qpg06apuf0sg8dftlh5arpqyrhfq3d37ca",
    "languages": ["en"]
  },
  {
    "name": "Jiayuan Li",
    "twitter": "@JiaOnTheGo",
    "desc": "Music Girl Media. 2verse. Nostr: npub18gdreyslgvdqll52fcxamwsq0y6w58n7apq83d99xlmw7ufqpa6qf0qut9",
    "npub": "npub18gdreyslgvdqll52fcxamwsq0y6w58n7apq83d99xlmw7ufqpa6qf0qut9",
    "languages": ["en"]
  },
  {
    "name": "jelle",
    "twitter": "@wires_wires",
    "desc": " find me on nostr npub1jellejv0rf4zv3rurf4yvxddylele5u5wrarkj26a7972466c8qqqhm3w7",
    "npub": "npub1jellejv0rf4zv3rurf4yvxddylele5u5wrarkj26a7972466c8qqqhm3w7",
    "languages": ["en"]
  },
  {
    "name": "williamsantiago@getalby.com",
    "twitter": "@WilliamSantiago",
    "desc": "CEO & Founder at PrivKey LLC #bitcoin #nostr: npub1h3fzzzeq60acjvnyvw34rpn5clkaueteffmkt3ln4ygekg9lcm0qhw96sj The Mission: The Separation of Money and State.",
    "npub": "npub1h3fzzzeq60acjvnyvw34rpn5clkaueteffmkt3ln4ygekg9lcm0qhw96sj",
    "languages": ["en"]
  },
  {
    "name": "Jerry ",
    "twitter": "@abigelephant",
    "desc": "Co-host of  @btcdiscovery  Co-founder of  @bitcoinerMY   jerry@lifpay.me #bitcoin nostr: npub1uzd74gql89r529hhpyx5jr777cky055gwq9kj8c4e4tzqpeqjedq4ut23m",
    "npub": "npub1uzd74gql89r529hhpyx5jr777cky055gwq9kj8c4e4tzqpeqjedq4ut23m",
    "languages": ["en"]
  },
  {
    "name": "Joey",
    "twitter": "@wen_5mil_BTC",
    "desc": "#Bitcoin #nostr:npub1sq3gzdjtmw0knsgxcfrne35qz47srr6hz49z3vkrewdny0zwpvzqnz46tn",
    "npub": "npub1sq3gzdjtmw0knsgxcfrne35qz47srr6hz49z3vkrewdny0zwpvzqnz46tn",
    "languages": ["en"]
  },
  {
    "name": "hezhao",
    "twitter": "@hezhaox",
    "desc": "成为时间的朋友 #nostr: npub1tkenv5zh2xfht8kmhg9wdjql02ss4f97kfunef9s64v0cl89d8gqxcsuaj",
    "npub": "npub1tkenv5zh2xfht8kmhg9wdjql02ss4f97kfunef9s64v0cl89d8gqxcsuaj",
    "languages": ["en"]
  },
  {
    "name": "Nathan Healey",
    "twitter": "@nathanhealey80",
    "desc": "An unexamined life is not worth living - Socrates #Bitcoin #nostr npub1hxxggwtcd4dy7v5unfpxe8e8h95g60zgrd5mrler8twc7cdu566qvfstyu",
    "npub": "npub1hxxggwtcd4dy7v5unfpxe8e8h95g60zgrd5mrler8twc7cdu566qvfstyu",
    "languages": ["en"]
  },
  {
    "name": "比特币橙子Trader",
    "twitter": "@chengzi_95330",
    "desc": "#Blockchain #Bitcoin #Lightning Network #Ethereum #Nostr npub1qug082ue37fq5pcqrj4v7p5nza49s7gmfc37jpa8ste06zsplrqsk 电报：http://t.me/zicheng_95330 频道：http://t.me/chengzi95330",
    "npub": "npub1qug082ue37fq5pcqrj4v7p5nza49s7gmfc37jpa8ste06zsplrqsk",
    "languages": ["en"]
  },
  {
    "name": "Huberto Leal - EU USO BTC",
    "twitter": "@euusobtc",
    "desc": "#nostr https://youtube.com/@HubertoLeal npub18046ghy2lwmt2846vqpr3lkqa7z23wrwu2yuqg4d4uacxd0kt45s4sk8xn #Bitcoin #holdbtc100 #euusobtc #KriptoSteel",
    "npub": "npub18046ghy2lwmt2846vqpr3lkqa7z23wrwu2yuqg4d4uacxd0kt45s4sk8xn",
    "languages": ["en"]
  },
  {
    "name": "Roku",
    "twitter": "@RokuMakabe",
    "desc": "#Bitcoin #nostr: npub12cfze0swmmvmx2qj5tu6k7aw5h45w84f0s34vl32hjega4dzekpsqa0dzy",
    "npub": "npub12cfze0swmmvmx2qj5tu6k7aw5h45w84f0s34vl32hjega4dzekpsqa0dzy",
    "languages": ["en"]
  },
  {
    "name": "samb.local",
    "twitter": "@samb_local",
    "desc": "intern at  @TeamLocalMoney . I'm in it for the tech and the memes. . #nostr npub1wfmaa0nc8jwhc8vxsvksetv4akxtr0p8jy9yhf2vmrrpjjvvex8q0x4an5",
    "npub": "npub1wfmaa0nc8jwhc8vxsvksetv4akxtr0p8jy9yhf2vmrrpjjvvex8q0x4an5",
    "languages": ["en"]
  },
  {
    "name": "Xenos",
    "twitter": "@337Xenos",
    "desc": "Anonymous 20yo #nostr: npub1ynqkn6vpa6auh7vgnerrhfrep823j4xlvarejg3mttkny5jm7gwq8ytucz",
    "npub": "npub1ynqkn6vpa6auh7vgnerrhfrep823j4xlvarejg3mttkny5jm7gwq8ytucz",
    "languages": ["en"]
  },
  {
    "name": "Wani",
    "twitter": "@WaniG_",
    "desc": "nerd at  @YureiSpirit  nostr #npub1nu27zetnwf0q2aujsfmyxvk4xkjtz5dxfrv8p0ey5c38hamyjzuqkw343l",
    "npub": "npub1nu27zetnwf0q2aujsfmyxvk4xkjtz5dxfrv8p0ey5c38hamyjzuqkw343l",
    "languages": ["en"]
  },
  {
    "name": "hodlcuban",
    "twitter": "@thebitofcoin",
    "desc": "Jesus follower, hodlcuban@getalby.com nostr: npub17nvfw7g53nxjghyd2zg552z06cke0jc0k69hj7ns7f9pw26j9kusap4u85",
    "npub": "npub17nvfw7g53nxjghyd2zg552z06cke0jc0k69hj7ns7f9pw26j9kusap4u85",
    "languages": ["en"]
  },
  {
    "name": "jesse_hodl",
    "twitter": "@jesse_hodl",
    "desc": "Nostr: npub18kmp3dwwwap4t0agtcejrll6jdea2w7rnexxa3xaa2nu85259nfqd8r3fs",
    "npub": "npub18kmp3dwwwap4t0agtcejrll6jdea2w7rnexxa3xaa2nu85259nfqd8r3fs",
    "languages": ["en"]
  },
  {
    "name": "moritz ",
    "twitter": "@moneyoftheppl",
    "desc": "#bitcoin is the money of the ppl | RELAI code LERNEN | #nostr npub1640eayxypvjzee6mtv6qpgkwy0u5fza8yzeqv7jpr9w0swv2h6yqrkrrle",
    "npub": "npub1640eayxypvjzee6mtv6qpgkwy0u5fza8yzeqv7jpr9w0swv2h6yqrkrrle",
    "languages": ["en"]
  },
  {
    "name": "Nic₿",
    "twitter": "@imNicB",
    "desc": "npub1qqqqqqpdfarn8u0wg9ayq43hl5xcrklmcmda3sx3e90sfmpah9esnddvpn #nostr",
    "npub": "npub1qqqqqqpdfarn8u0wg9ayq43hl5xcrklmcmda3sx3e90sfmpah9esnddvpn",
    "languages": ["en"]
  },
  {
    "name": "Kelly Brewster",
    "twitter": "@kbbrewster",
    "desc": "CEO  @_WolfNYC , a startup accelerator dedicated to #Lightning . Former CMO  @NYDIG  and former  @GoldmanSachs . #nostr: npub1zh53a7krdcxuxqfe5dg6rgynqfm9y6etf8hdu90",
    "npub": "npub1zh53a7krdcxuxqfe5dg6rgynqfm9y6etf8hdu90",
    "languages": ["en"]
  },
  {
    "name": "Jonathan Adly",
    "twitter": "@Jonathan_Adly_",
    "desc": "Software developer & Pharmacist - : jonathan@getalby.com. NOSTR : #npub14j58kwpzeh3r7rmmams3e6e0fcrymgzlqqvg9hm55g6dfvcgvq6qndrugn",
    "npub": "npub14j58kwpzeh3r7rmmams3e6e0fcrymgzlqqvg9hm55g6dfvcgvq6qndrugn",
    "languages": ["en"]
  },
  {
    "name": "PTJ ∞/21M Troll Demon",
    "twitter": "@petrijaervinen",
    "desc": "Probably a Troll Demon #Bitcoin Stack or Die Motörhead #Nostr npub1lzacp88vsn3lzs2e7al0sewl7gwnxj9mytsfav7rpm4eczm62ulq27724j",
    "npub": "npub1lzacp88vsn3lzs2e7al0sewl7gwnxj9mytsfav7rpm4eczm62ulq27724j",
    "languages": ["en"]
  },
  {
    "name": "Rick Allen",
    "twitter": "@stoodder",
    "desc": "One smug mug. Full stack engineer and business builder. #bitcoin nostr: npub1hm40ds8kgs5hj6l2gggdf0atg4s7pxm5wuaa9t7qw550lm60shjsevcz4k",
    "npub": "npub1hm40ds8kgs5hj6l2gggdf0atg4s7pxm5wuaa9t7qw550lm60shjsevcz4k",
    "languages": ["en"]
  },
  {
    "name": "The Bitcoin Commons",
    "twitter": "@BTC_commons",
    "desc": "Used to work for labor unions. Now I work on fixing our monetary system. #nostr: npub1v2j9qp8sl8av3klnxny29pnkle8cmucc9m7unwrzauaehvhl0rzsrdgaq7",
    "npub": "npub1v2j9qp8sl8av3klnxny29pnkle8cmucc9m7unwrzauaehvhl0rzsrdgaq7",
    "languages": ["en"]
  },
  {
    "name": "Gerardo",
    "twitter": "@Frosty_Trails",
    "desc": "Nostr: #npub1mvenvvmenmmlnkajjeate0kk27mvvatcgk0jhs4a3r2cg2nql92qzsgn3v",
    "npub": "npub1mvenvvmenmmlnkajjeate0kk27mvvatcgk0jhs4a3r2cg2nql92qzsgn3v",
    "languages": ["en"]
  },
  {
    "name": "Dominic K.",
    "twitter": "@DominicKuch",
    "desc": "#Bitcoin maxi pleb! node runner! #nostr : npub1t3s0sj5x8w6gdsa8qeml8fw4ntd9djsq3mzxfk6efux852syst6qaxjwuu",
    "npub": "npub1t3s0sj5x8w6gdsa8qeml8fw4ntd9djsq3mzxfk6efux852syst6qaxjwuu",
    "languages": ["en"]
  },
  {
    "name": "miljan",
    "twitter": "@mbraticevic",
    "desc": "Building on #bitcoin and #nostr: npub16c0nh3dnadzqpm76uctf5hqhe2lny344zsmpm6feee9p5rdxaa9q586nvr",
    "npub": "npub16c0nh3dnadzqpm76uctf5hqhe2lny344zsmpm6feee9p5rdxaa9q586nvr",
    "languages": ["en"]
  },
  {
    "name": "BRocketMiner",
    "twitter": "@BTCSpeedBoat",
    "desc": "Mining and Stacking #BITCOIN Daily! Diamond Hands!Not Your Keys Not Your Coin! NOSTR npub10uwhq8vm7wuj7npgztjrp0g9sd5f2s8jk0ydqzsa06633np6ftyqgcn5pz",
    "npub": "npub10uwhq8vm7wuj7npgztjrp0g9sd5f2s8jk0ydqzsa06633np6ftyqgcn5pz",
    "languages": ["en"]
  },
  {
    "name": "Alex Lewin",
    "twitter": "@_AlexLewin",
    "desc": "#bitcoin Lightning dev  @blockspaces_io  Expert in Y2K preparedness  @terminusbtc  #nostr npub16anjdksmvn5x08vtden04n64rw5k7fsjmedpw8avsx8wsh8ruhlq076pfx",
    "npub": "npub16anjdksmvn5x08vtden04n64rw5k7fsjmedpw8avsx8wsh8ruhlq076pfx",
    "languages": ["en"]
  },
  {
    "name": "Cody",
    "twitter": "@SweetLou_38",
    "desc": "-Jesus rose from the dead, the world is not what it seems- Find me on #nostr npub156lyq7fl7c6hzn8rpfne8qwend5rk953mcgqhmxgsse837gz924qcfwvz7 http://snort.social",
    "npub": "npub156lyq7fl7c6hzn8rpfne8qwend5rk953mcgqhmxgsse837gz924qcfwvz7",
    "languages": ["en"]
  },
  {
    "name": "Maple : 13%'r",
    "twitter": "@GhostofMaplHodl",
    "desc": "#Bitcoin Only! NOSTR:npub1hqrqk49gdkdgl2cyx2xwzd8s7teq6t3vvlqj3yedp0anwv4t78mqxtds60 maplehodl@getalby.com",
    "npub": "npub1hqrqk49gdkdgl2cyx2xwzd8s7teq6t3vvlqj3yedp0anwv4t78mqxtds60",
    "languages": ["en"]
  },
  {
    "name": "Jan Rothen ∞/21m ",
    "twitter": "@janrothen",
    "desc": "#Bitcoin: Fix the money, fix the world. nostr: npub1gn27aka9v0znne2c8c2sf6rqayy23g7mcqg8hgqdfs4r7mtech8qyqx99z",
    "npub": "npub1gn27aka9v0znne2c8c2sf6rqayy23g7mcqg8hgqdfs4r7mtech8qyqx99z",
    "languages": ["en"]
  },
  {
    "name": "InPoW-I-trust",
    "twitter": "@LIFEinPoW",
    "desc": "Oh Yes! nostr:#npub1p8dhuwwqnx7fkh4glsr2u3xzngqh26pyy9g45rlx72xyd358efvsa0egpe",
    "npub": "npub1p8dhuwwqnx7fkh4glsr2u3xzngqh26pyy9g45rlx72xyd358efvsa0egpe",
    "languages": ["en"]
  },
  {
    "name": "Tek Tok Canada",
    "twitter": "@tektokca",
    "desc": "We are a Canadian Tech Blog bringing you news & reviews. Nostr Verification: npub1l498j2tczg7st0f3j2qljkrtxsjxpwthn2syl0zqnw8ve5ytv8wsrzz7yq",
    "npub": "npub1l498j2tczg7st0f3j2qljkrtxsjxpwthn2syl0zqnw8ve5ytv8wsrzz7yq",
    "languages": ["en"]
  },
  {
    "name": "ᚺAᚱᚱᛉ ᚯ IV ♈︎ ∞/21ᛖ",
    "twitter": "@SmellinBenzoin",
    "desc": "#Bitcoin |Baseball | Star Wars| Omnist | | Computer Science | Information Technology | Data Science #Nostr: npub1e83f7ldw80qs7l67les452yy2k4ast7r975ke8jyt4",
    "npub": "npub1e83f7ldw80qs7l67les452yy2k4ast7r975ke8jyt4",
    "languages": ["en"]
  },
  {
    "name": "Hornet Hodl ",
    "twitter": "@BitcoinHornet",
    "desc": "#Bitcoin maximalist. #Pleb Nostr: npub1m26flc5r6mlfvtktwv7vauagdmdrche4u2k4yd6w3lmqsjlfa7wqaterdu",
    "npub": "npub1m26flc5r6mlfvtktwv7vauagdmdrche4u2k4yd6w3lmqsjlfa7wqaterdu",
    "languages": ["en"]
  },
  {
    "name": "david strayhorn",
    "twitter": "@davidstrayhorn",
    "desc": "the concept graph & the grapevine nostr:npub1u5njm6g5h5cpw4wy8xugu62e5s7f6fnysv0sj0z3a8rengt2zqhsxrldq3",
    "npub": "npub1u5njm6g5h5cpw4wy8xugu62e5s7f6fnysv0sj0z3a8rengt2zqhsxrldq3",
    "languages": ["en"]
  },
  {
    "name": "Lewis ",
    "twitter": "@Nomadicbushido",
    "desc": "Bitcoin is my reserve treasury asset #Nostr: npub1upprd6u02rtaqgz4x7ffvj2s2k7fqvxmj9ahylq50sgcmcz5hgmqg7t3s3",
    "npub": "npub1upprd6u02rtaqgz4x7ffvj2s2k7fqvxmj9ahylq50sgcmcz5hgmqg7t3s3",
    "languages": ["en"]
  },
  {
    "name": "Rock",
    "twitter": "@rockymedure",
    "desc": "Bitcoin Design Lead :  @cashapp  #bitcoin | nostr: npub1yauhh489aefdkzsewe5vj2u68elzxlsln7nn5yxr34e3c22vljdqkn7h9m",
    "npub": "npub1yauhh489aefdkzsewe5vj2u68elzxlsln7nn5yxr34e3c22vljdqkn7h9m",
    "languages": ["en"]
  },
  {
    "name": "Nisupulla ",
    "twitter": "@NisupullaFIN",
    "desc": "Game enthusiast. #nostr @ pubkey: npub1t9ex5jwhqe8zxru3jtrcd3jtp42r4dg3eh4nsutn6dez3ml774wsm8ksnk",
    "npub": "npub1t9ex5jwhqe8zxru3jtrcd3jtp42r4dg3eh4nsutn6dez3ml774wsm8ksnk",
    "languages": ["en"]
  },
  {
    "name": "Ye Olde Holdlonaut No. 6",
    "twitter": "@YeOldeHodler",
    "desc": "#Bitcoin is just money.  Bija Nostr client dev npub1qqqqqqqut3z3jeuxu70c85slaqq4f87unr3vymukmnhsdzjahntsfmctgs Bitcoin events in Brighton  @brighton_btc",
    "npub": "npub1qqqqqqqut3z3jeuxu70c85slaqq4f87unr3vymukmnhsdzjahntsfmctgs",
    "languages": ["en"]
  },
  {
    "name": "Zenon ",
    "twitter": "@Zenon_Citium",
    "desc": " It can only be affirmed in geometry. #Bitcoin Cheap sats are hard to catch  #nostr: npub19pc6m4a0glk5e02r7zl63u78qxk3xcuecg6qc0jyvt8fr20wh7pqujd5xk",
    "npub": "npub19pc6m4a0glk5e02r7zl63u78qxk3xcuecg6qc0jyvt8fr20wh7pqujd5xk",
    "languages": ["en"]
  },
  {
    "name": "Jarrett Vaughan",
    "twitter": "@JarrettVaughan",
    "desc": "Adventurer, Musician, Crypto and Marketing Professor, Crypto and Real Estate Investor, TheWeb3Prof #nostr npub1a06sxx4wjs9cj2a3xwydgvsu9d4t95xdt477c853jjnglpsj",
    "npub": "npub1a06sxx4wjs9cj2a3xwydgvsu9d4t95xdt477c853jjnglpsj",
    "languages": ["en"]
  },
  {
    "name": "Arul Prakash",
    "twitter": "@arulprakash",
    "desc": "I profile code, shitpost about web dev, passionate about memes, opensource and bitcoin Nostr npub1dqz682yfaghjtvha2pkau4edknc7x32kftp2rcsx8ke6awlp7a5q27w5h8",
    "npub": "npub1dqz682yfaghjtvha2pkau4edknc7x32kftp2rcsx8ke6awlp7a5q27w5h8",
    "languages": ["en"]
  },
  {
    "name": "Raphael Bartolomé",
    "twitter": "@rbartolome",
    "desc": "Mac and iOS developer. In the early days I was a WO guy. @rbartolome@mastodon.cloud nostr: npub1x6xsf826lq8cfqlvgka6v2wnf0d3w3lg3nwe83ceqfqr9c2gm4uq85z56z",
    "npub": "npub1x6xsf826lq8cfqlvgka6v2wnf0d3w3lg3nwe83ceqfqr9c2gm4uq85z56z",
    "languages": ["en"]
  },
  {
    "name": "santiagolouro",
    "twitter": "@santiagolouro",
    "desc": "Strategy at  @Zebedeeio  | Previously:  @nuribanking  • tip/pay:santiagolouro@zbd.gg • nostr: #npub15sxcxl8gxqxunlmtsysm4wk40r3z4nxtpjuyng8psa22dtpz9gwsf2yvv2",
    "npub": "npub15sxcxl8gxqxunlmtsysm4wk40r3z4nxtpjuyng8psa22dtpz9gwsf2yvv2",
    "languages": ["en"]
  },
  {
    "name": " Kieran",
    "twitter": "@_v0ld",
    "desc": "#Bitcoin  v0l@getalby.com Building https://snort.social nostr://npub1v0lxxxxutpvrelsksy8cdhgfux9l6a42hsj2qzquu2zk7vc9qnkszrqj49",
    "npub": "npub1v0lxxxxutpvrelsksy8cdhgfux9l6a42hsj2qzquu2zk7vc9qnkszrqj49",
    "languages": ["en"]
  },
  {
    "name": "Frank Graffagnino",
    "twitter": "@fgraffagnino",
    "desc": "nostr key: npub14fdgx6272tsdqmydraz2p58jr68ml9w9d5aytw49j6kk4l5pznvsgadn2s",
    "npub": "npub14fdgx6272tsdqmydraz2p58jr68ml9w9d5aytw49j6kk4l5pznvsgadn2s",
    "languages": ["en"]
  },
  {
    "name": "Rafa ₿",
    "twitter": "@txapotxapa",
    "desc": "bush deep maximalist, cultivator. #Bitcoin supremacist #permaculturepleb #nostr #npub19dthxrkpe3rd94t97p9a4svnqqmr8kvznm8eyqy70yrxh884c0kqlnqv0g",
    "npub": "npub19dthxrkpe3rd94t97p9a4svnqqmr8kvznm8eyqy70yrxh884c0kqlnqv0g",
    "languages": ["en"]
  },
  {
    "name": "Matth",
    "twitter": "@matthloos",
    "desc": "Enthusiast Electrical Engineer working with everything around EVs and software. nostr: npub1wnkgsjrsnxh9fc604duyc0enu285j54hvkjere7hfcne7dz3ny0sxncy5a",
    "npub": "npub1wnkgsjrsnxh9fc604duyc0enu285j54hvkjere7hfcne7dz3ny0sxncy5a",
    "languages": ["en"]
  },
  {
    "name": "Longfin",
    "twitter": "@_longfin",
    "desc": "Fetch me another Mai Tai #bitcoin Nostr npub1mtnkelvt6lskxjk8xz8nvrqdyulj359a4q6snu68nee2f43dh47sa6pyt9",
    "npub": "npub1mtnkelvt6lskxjk8xz8nvrqdyulj359a4q6snu68nee2f43dh47sa6pyt9",
    "languages": ["en"]
  },
  {
    "name": "b_xt_r",
    "twitter": "@b_xt_r",
    "desc": "Record holder for oldest active Twitter account with the fewest followers. nostr: npub1m6cphmg6wxhflvgyvw4499tpflfrux32424f5z5w0dwg8rd2mazqa6msm3",
    "npub": "npub1m6cphmg6wxhflvgyvw4499tpflfrux32424f5z5w0dwg8rd2mazqa6msm3",
    "languages": ["en"]
  },
  {
    "name": "Darkmaster pleb",
    "twitter": "@darkmasterpleb",
    "desc": "tech dev. & sats stacker. #bitcoin is freedom and peace. nostr: #npub1an6j9lkx42wekdj5et7kr9mdl3p74lsfc53tnyzrep472f7uw76qlu88r0",
    "npub": "npub1an6j9lkx42wekdj5et7kr9mdl3p74lsfc53tnyzrep472f7uw76qlu88r0",
    "languages": ["en"]
  },
  {
    "name": "Hubert",
    "twitter": "@hubert_btc",
    "desc": "#Bitcoin Nostr: npub19kuz38yurwm45r75224lrgzn2d96zcj703ecdhg9h5lry5n9vs0qz4m22v",
    "npub": "npub19kuz38yurwm45r75224lrgzn2d96zcj703ecdhg9h5lry5n9vs0qz4m22v",
    "languages": ["en"]
  },
  {
    "name": "J  A N, sin hache.",
    "twitter": "@joandice",
    "desc": "Transitioning from Teaching  to WebDev . Currently learning #Javascript && #NodeJS | Nostr: #npub1dt2ht3j43d446keptmahde6nnhgzdmud7ql87tc5q",
    "npub": "npub1dt2ht3j43d446keptmahde6nnhgzdmud7ql87tc5q",
    "languages": ["en"]
  },
  {
    "name": "Hunter Beast",
    "twitter": "@cryptoquick",
    "desc": "Developer.  Rust  Bitcoin  Lightning  RGB   @BitMask_App  Find me on Nostr  npub1qqqqqqqrxtrcx8vut2vlrqa0c2qn5mmf59hdmflkls8dsyg9vmnqsclxwk",
    "npub": "npub1qqqqqqqrxtrcx8vut2vlrqa0c2qn5mmf59hdmflkls8dsyg9vmnqsclxwk",
    "languages": ["en"]
  },
  {
    "name": "Kieran Nolan ",
    "twitter": "@KieranDNolan",
    "desc": " kdnolan@getalby.com http://vida.page/kierandnolan nostr: npub1cyla8qgt9gv8y6ydv8s2prt89h8afc0sr2kaz64 http://getoffzeropod.com http://theschoolofbitcoin.com http://bitcoinaggregated.com",
    "npub": "npub1cyla8qgt9gv8y6ydv8s2prt89h8afc0sr2kaz64",
    "languages": ["en"]
  },
  {
    "name": "Devoto ",
    "twitter": "@DevotoBTC",
    "desc": "#Bitcoin is the soundest money | GFY  Nostr: npub1220w8axruazp6s0j6xyl0jjfuse5rw787x6yvysjm2m3ujpqt2fsv37r2u",
    "npub": "npub1220w8axruazp6s0j6xyl0jjfuse5rw787x6yvysjm2m3ujpqt2fsv37r2u",
    "languages": ["en"]
  },
  {
    "name": "BTCYN ∞/21M ",
    "twitter": "@BTCYN",
    "desc": "#bitcoin #Bitcoin #BTC buy bitcoin, stack sats  http://SwanBitcoin.com/BTCYN #nostr: npub1dsmdy4jje0rmw39mgwmfvh89ef6pmf4vkufeeutjmazzt0wwxaqs0p42dp",
    "npub": "npub1dsmdy4jje0rmw39mgwmfvh89ef6pmf4vkufeeutjmazzt0wwxaqs0p42dp",
    "languages": ["en"]
  },
  {
    "name": "@claytantor@mastodon.social",
    "twitter": "@claytantor",
    "desc": "founder at http://rapaygo.com #BTC #LightningNetworkln:clay@rapaygo .com nostr:npub1uhnl3zayujw3n899dk07fn3gh7gvnfc0jmd0atjn2",
    "npub": "npub1uhnl3zayujw3n899dk07fn3gh7gvnfc0jmd0atjn2",
    "languages": ["en"]
  },
  {
    "name": "NY_HODL (shitcoin disrespecter)",
    "twitter": "@NY_HODL",
    "desc": "Money is a long story and #Bitcoin is the last chapter. ny_hodl@proton.me Nostr: npub1hgwahnvxmm39c2zkmqyeur7tl25fh8w7hfgaj0ed6elfmc6xkh8qvrdkft",
    "npub": "npub1hgwahnvxmm39c2zkmqyeur7tl25fh8w7hfgaj0ed6elfmc6xkh8qvrdkft",
    "languages": ["en"]
  },
  {
    "name": "HODL ₿udda",
    "twitter": "@Bitcoin_Budda",
    "desc": "lifelong learner, value/momentum investor, and #Bitcoin HODLer of last resort // nostr: npub153twg7r9dpfa6s2gtxlvtsua3kkxh4y3ywec8nm6jcevzvmzyj0s9m7cpw",
    "npub": "npub153twg7r9dpfa6s2gtxlvtsua3kkxh4y3ywec8nm6jcevzvmzyj0s9m7cpw",
    "languages": ["en"]
  },
  {
    "name": "Christian Chiarulli ",
    "twitter": "@chrisatmachine",
    "desc": " YT: http://youtube.com/@chrisatmachine  LunarVim: http://lunarvim.org Zap: http://zapzsh.org #nostr: npub1ygzj9skr9val9yqxkf67yf9jshtyhvvl0x76jp5er09nsc0p3j6qr260k2 #Bitcoin",
    "npub": "npub1ygzj9skr9val9yqxkf67yf9jshtyhvvl0x76jp5er09nsc0p3j6qr260k2",
    "languages": ["en"]
  },
  {
    "name": "Mr.MickeyAxelrod",
    "twitter": "@BobbyCryptoRod",
    "desc": "Better to remain silent and be thought a fool than to speak and remove all doubt #nostr #BTC  npub1na88x3qezhs3xu8vtv2ujfn2ue5qmjuaretw9e4wlt7wk7cq6q0qwetqwn",
    "npub": "npub1na88x3qezhs3xu8vtv2ujfn2ue5qmjuaretw9e4wlt7wk7cq6q0qwetqwn",
    "languages": ["en"]
  },
  {
    "name": "The Golden Teacher",
    "twitter": "@Mycoteric",
    "desc": "#Bitcoin pleb. Nostr: npub15nhx3zjenj2f8wryrnrpnpl0g2m426apu7wnt09f9gwuuxrd4jzstuwtst",
    "npub": "npub15nhx3zjenj2f8wryrnrpnpl0g2m426apu7wnt09f9gwuuxrd4jzstuwtst",
    "languages": ["en"]
  },
  {
    "name": "Bitcoin Or Nothing",
    "twitter": "@BtcOrNothing21M",
    "desc": "How you do one thing is how you do everything #Bitcoin Nostr: npub1v6npgwjjm6xt5zzysjwmgzgswsr8lyz96lucy4wgygdududa7s5qq232np",
    "npub": "npub1v6npgwjjm6xt5zzysjwmgzgswsr8lyz96lucy4wgygdududa7s5qq232np",
    "languages": ["en"]
  },
  {
    "name": "Emilio Marcovici Zavala",
    "twitter": "@markovswitch",
    "desc": "Ecuadorian Jew working 7+ yrs in cannabis and custom software | #nostr: npub1mlerl26wnrgt9cqh5a8trzz0kxadu9727cthx82252glygt792qqc0z9n6",
    "npub": "npub1mlerl26wnrgt9cqh5a8trzz0kxadu9727cthx82252glygt792qqc0z9n6",
    "languages": ["en"]
  },
  {
    "name": "Jimmy",
    "twitter": "@BunkFreamon",
    "desc": "#bitcoin #nostr: npub15r70r990f0pxftznws0ttvx3f3d9yqldurrzd9a7xp9k62k4scyqxkhvth",
    "npub": "npub15r70r990f0pxftznws0ttvx3f3d9yqldurrzd9a7xp9k62k4scyqxkhvth",
    "languages": ["en"]
  },
  {
    "name": "Andrea Otto",
    "twitter": "@otto_biography",
    "desc": "Video Editor   @bitcoinmagazine  Nostr: npub1tl63agqgc9ghyty7w9myuql0gadcp2jzpgdtxckylptx4txw5j4qz70zp4",
    "npub": "npub1tl63agqgc9ghyty7w9myuql0gadcp2jzpgdtxckylptx4txw5j4qz70zp4",
    "languages": ["en"]
  },
  {
    "name": "knickerbocker crisis survivor",
    "twitter": "@Brethberie",
    "desc": "don’t trust, verify. working on fixing the money and the food. constantly committing thought crimes nostr: npub1kg80cy7pma0ng848ky57jt4es7d2vppua52zwx0lp0f4fmu",
    "npub": "npub1kg80cy7pma0ng848ky57jt4es7d2vppua52zwx0lp0f4fmu",
    "languages": ["en"]
  },
  {
    "name": "@Lizard@bitcoinlizard.net",
    "twitter": "@BitcoinLizard",
    "desc": "Just a #Bitcoin Lizard trying to gobble up some sats. Matrix:  @lizard :http://bitcoinlizard.net Nostr: npub1k6f0ecam577lay8mm8azfvs3u4xvl23q82zpc3htee6ath09gg0s9ftryn",
    "npub": "npub1k6f0ecam577lay8mm8azfvs3u4xvl23q82zpc3htee6ath09gg0s9ftryn",
    "languages": ["en"]
  },
  {
    "name": "✠",
    "twitter": "@jodobear",
    "desc": "3A9C7E8730284351 #nostr npub16c9a45p5dr6l3jzmrvgdh9m7xy994tatxd6sm7kmxaygkq4lertsfnacfm",
    "npub": "npub16c9a45p5dr6l3jzmrvgdh9m7xy994tatxd6sm7kmxaygkq4lertsfnacfm",
    "languages": ["en"]
  },
  {
    "name": "yoeyg",
    "twitter": "@JG3693",
    "desc": "Pokémon cards are recession proof. Nostr public key: npub1r667cqq3kjzghtj6l6t78krmcl0hk6knl8088huy4q0c3tujhvusz6x5dh",
    "npub": "npub1r667cqq3kjzghtj6l6t78krmcl0hk6knl8088huy4q0c3tujhvusz6x5dh",
    "languages": ["en"]
  },
  {
    "name": "Bullish Mike ",
    "twitter": "@Mike__21__",
    "desc": "#bitcoin Nostr: npub1glcf3qytes2w49s0q5z7xazyhgpmwjy0vs78dph3ra5q7k6suvqst6pfhv",
    "npub": "npub1glcf3qytes2w49s0q5z7xazyhgpmwjy0vs78dph3ra5q7k6suvqst6pfhv",
    "languages": ["en"]
  },
  {
    "name": "Son of Satoshi",
    "twitter": "@Angelo_Somers",
    "desc": "Brother of  @bitcointheo  - Author of “Do/ Bitcoin” — link below  Nostr: npub1r0fj5wr200n0dz9nm37ysrhuy8xeg66ratq5hf960q62caaz8e5sqpuzz3",
    "npub": "npub1r0fj5wr200n0dz9nm37ysrhuy8xeg66ratq5hf960q62caaz8e5sqpuzz3",
    "languages": ["en"]
  },
  {
    "name": "Moses Finlay",
    "twitter": "@mosesfinlay",
    "desc": "17-year-old full stack developer, founder of  @jointracker . #bitcoin #nostr: npub1zhvr5ckhxvmq6hy2ja2zfwkaw7cz2yj3j0rz2y337ww3e79vxnjq6k4vc4",
    "npub": "npub1zhvr5ckhxvmq6hy2ja2zfwkaw7cz2yj3j0rz2y337ww3e79vxnjq6k4vc4",
    "languages": ["en"]
  },
  {
    "name": "Casey Ward",
    "twitter": "@iCaseyWard",
    "desc": "Evangelist | #bitcoin not crypto | foodie  | hip hop head  | healthy  | #nostr: npub10mtfj32tmmhjutkjaax0c5wduh42e3f0sl4nmy02vedzrjvnnr3s4st858",
    "npub": "npub10mtfj32tmmhjutkjaax0c5wduh42e3f0sl4nmy02vedzrjvnnr3s4st858",
    "languages": ["en"]
  },
  {
    "name": "Austin Mitchell",
    "twitter": "@aisaacsmitchell",
    "desc": "Co-founder & CEO Synota | transactive energy | #bitcoin & | nostr: npub1sw9mgd8nucq4ny8hjcc79uyht3a4498ekz573t5ngl7xhdqk0rhqmsszgt",
    "npub": "npub1sw9mgd8nucq4ny8hjcc79uyht3a4498ekz573t5ngl7xhdqk0rhqmsszgt",
    "languages": ["en"]
  },
  {
    "name": "FREEDOM ",
    "twitter": "@_edgarfriendly",
    "desc": "Non-compliant organism in a collectivist hell hole loop℠㋏ Decentralize! nostr: npub1g4wz7j7m32ecyvslhv6rqxujps5uxxs9xhevtefjgwrzw6aaj8ysnn0d6z",
    "npub": "npub1g4wz7j7m32ecyvslhv6rqxujps5uxxs9xhevtefjgwrzw6aaj8ysnn0d6z",
    "languages": ["en"]
  },
  {
    "name": "Hodladi",
    "twitter": "@Hodladi",
    "desc": "Bitcoin maximalist | Node runner | Privacy advocate | Nostr Pubkey: npub1sh26ud282kaeqdelypy95g929grat7kr2uhjhn6pdaffg6q6ra4s42gjj5 | https://hodla.nu",
    "npub": "npub1sh26ud282kaeqdelypy95g929grat7kr2uhjhn6pdaffg6q6ra4s42gjj5",
    "languages": ["en"]
  },
  {
    "name": "Alessandro Manfredi",
    "twitter": "@AlleManfredi",
    "desc": "CTO at  @eidoo_io  #lens: alessandro.lens #nostr: npub1ttlu40vu4pltj420tq9jfe93jwgek3m3wpcq2nrfw0xdx9sg7m6qw3aa8s",
    "npub": "npub1ttlu40vu4pltj420tq9jfe93jwgek3m3wpcq2nrfw0xdx9sg7m6qw3aa8s",
    "languages": ["en"]
  },
  {
    "name": "Ghost of Puppers",
    "twitter": "@puppertoshi2",
    "desc": "#bitcoin | Pure  |  Nostr: npub1dw4zeajfe7jctazfysf7jamravkncjdsdyype692v0wg2ggc9kxsvau6se",
    "npub": "npub1dw4zeajfe7jctazfysf7jamravkncjdsdyype692v0wg2ggc9kxsvau6se",
    "languages": ["en"]
  },
  {
    "name": "Whisky Bravo Mike, a 13%er",
    "twitter": "@noshitcoiner",
    "desc": "#bitcoin maxi. bitcoiner because my kids deserve better. Not your keys, not your corn. nostr:npub1emy78vq7qscxpsltjnddf55nxzf8ck9va0wuyyeshva55geln7cqxlar03",
    "npub": "npub1emy78vq7qscxpsltjnddf55nxzf8ck9va0wuyyeshva55geln7cqxlar03",
    "languages": ["en"]
  },
  {
    "name": "BitcoinJiuJitsu",
    "twitter": "@BitcoinJiuJitsu",
    "desc": "Stack #Bitcoin  Do #JiuJitsu  #nostr npub1fsew6f5h9kddwl7lrss05w7cnsm2ap4lmeqvalvdjzx5szj7et5qsmjmsx",
    "npub": "npub1fsew6f5h9kddwl7lrss05w7cnsm2ap4lmeqvalvdjzx5szj7et5qsmjmsx",
    "languages": ["en"]
  },
  {
    "name": "",
    "twitter": "@adambraunton",
    "desc": "#Mastodon: @abraunton@toot.community #Nostr: npub1c3twfcadj7szhcmt8quctan4pdn6whujn3khk9qvsy59fv5nd02q4qm42k",
    "npub": "npub1c3twfcadj7szhcmt8quctan4pdn6whujn3khk9qvsy59fv5nd02q4qm42k",
    "languages": ["en"]
  },
  {
    "name": "zach ₿",
    "twitter": "@Rudefire",
    "desc": "Devil in baggy pants. Technical Project Manager  @zebedeeio . #BTC nostr: npub1ddpccv6glfzpgsaptc84jn28a5nd82rlzwkcjak5ctz6dkdml3dspxm4dy",
    "npub": "npub1ddpccv6glfzpgsaptc84jn28a5nd82rlzwkcjak5ctz6dkdml3dspxm4dy",
    "languages": ["en"]
  },
  {
    "name": "Matt Montis ",
    "twitter": "@mattmontis",
    "desc": "nostr damus npub1dkrqw02fmv7kw3dec4hsk4z3hxjx9etrw7452ys9p9shpc5xlxes8ntmdv",
    "npub": "npub1dkrqw02fmv7kw3dec4hsk4z3hxjx9etrw7452ys9p9shpc5xlxes8ntmdv",
    "languages": ["en"]
  },
  {
    "name": "Hardrockcrypto",
    "twitter": "@hardrockcrypto",
    "desc": "#Crypto entertainment  #bitcoin ed #Altcoins a 360°  TUTTO GRATIS  Unisciti alla RIVOLUZIONE #Nostr: npub13sjpeg7fqcwvu0ej473es796t895zxssd6hj77pd",
    "npub": "npub13sjpeg7fqcwvu0ej473es796t895zxssd6hj77pd",
    "languages": ["en"]
  },
  {
    "name": "Axel Hamburch 丰",
    "twitter": "@AxelHamburch",
    "desc": "#Bitcoin #Nostr: npub1yynthqj2zv663t0v8jtmtnwm34ak2fwe6zys043weqlnxgyuw7qqnk23ds",
    "npub": "npub1yynthqj2zv663t0v8jtmtnwm34ak2fwe6zys043weqlnxgyuw7qqnk23ds",
    "languages": ["en"]
  },
  {
    "name": "Mr.₿",
    "twitter": "@Boolanonn",
    "desc": "Down the Rabbit Hole we go! #AlwaysLearning Don’t trust, verify! #Bitcoin #npub1u3dqueymwkpkzq6jac2vj35jngr5mfv0u0v78rxgzvh70qmx4ussyxs2sk #Nostr",
    "npub": "npub1u3dqueymwkpkzq6jac2vj35jngr5mfv0u0v78rxgzvh70qmx4ussyxs2sk",
    "languages": ["en"]
  },
  {
    "name": "Seth Baylis, CPA",
    "twitter": "@sethbaylis",
    "desc": "@auburnu   ‘21 ‘20 #Bitcoin |  | #Nostr #npub1w00myu2dzuq8t05ks0hfz4jgd66aarf5m5u6s7w2mtj52xr9v32s0qwa8u",
    "npub": "npub1w00myu2dzuq8t05ks0hfz4jgd66aarf5m5u6s7w2mtj52xr9v32s0qwa8u",
    "languages": ["en"]
  },
  {
    "name": "sir bitcoin",
    "twitter": "@sir_bitcoin",
    "desc": "Nostr: npub1kx6pmrxtt92rl9760evehmtz0n958acwu9xyyajnkdu5wzx50hyqx57v94",
    "npub": "npub1kx6pmrxtt92rl9760evehmtz0n958acwu9xyyajnkdu5wzx50hyqx57v94",
    "languages": ["en"]
  },
  {
    "name": "Klaas Schwub (God Of Qraziness)",
    "twitter": "@dabrofin",
    "desc": "#Bitcoin Satsinator #Nostr: npub1tage6wg4vnnf0dchu76w9h9la4prj7g39dyajay37k82lw6r38kqjt68a7 http://paynym.is/+orangewildflo…",
    "npub": "npub1tage6wg4vnnf0dchu76w9h9la4prj7g39dyajay37k82lw6r38kqjt68a7",
    "languages": ["en"]
  },
  {
    "name": "Gollum",
    "twitter": "@GollumHodl",
    "desc": "#Bitcoin #LightningNetwork  Gollum.BTC #nostr Gollum@21m.fr npub1sa8s53pm2xmfmzpxg0c9w85kkuhne8k59mukkm7q2lptkla63yussuyqch",
    "npub": "npub1sa8s53pm2xmfmzpxg0c9w85kkuhne8k59mukkm7q2lptkla63yussuyqch",
    "languages": ["en"]
  },
  {
    "name": "Dillweed",
    "twitter": "@dillweed",
    "desc": "nostr npub1fl2qte892zsftpztk0w22h7226kdda77g4c6jkzyvx45jpxvm2eqqe234z",
    "npub": "npub1fl2qte892zsftpztk0w22h7226kdda77g4c6jkzyvx45jpxvm2eqqe234z",
    "languages": ["en"]
  },
  {
    "name": "LayerLNW",
    "twitter": "@LayerLNW",
    "desc": "∞/21M #Bitcoin Nostr: npub1x0y7maaduxgcs6zejufkumlmfmvfjwgh37jlyfv59r0pe5eszwqqt7w3zq",
    "npub": "npub1x0y7maaduxgcs6zejufkumlmfmvfjwgh37jlyfv59r0pe5eszwqqt7w3zq",
    "languages": ["en"]
  },
  {
    "name": "WE_ARE_ALL_SATOSHI ∞/21M",
    "twitter": "@SatoshiLives21M",
    "desc": " weareallsatoshi21mforever@zbd.gg ₿itcoin only. Shadowy Super-Coder , psychopath. Nostr  npub1yjzf86mnlkw8s9ghzts77y4xv62dh2yn75n5laxhu6wndzp0nu5q9wrrr2",
    "npub": "npub1yjzf86mnlkw8s9ghzts77y4xv62dh2yn75n5laxhu6wndzp0nu5q9wrrr2",
    "languages": ["en"]
  },
  {
    "name": "₿acklaidluke",
    "twitter": "@backlaidluke",
    "desc": "Even though it might not look like it, I think 2020 will mark the starting point for the best times of humanity. #bitcoin Nostr: npub1rpr0h6y395df04cyzcn8uduf3",
    "npub": "npub1rpr0h6y395df04cyzcn8uduf3",
    "languages": ["en"]
  },
  {
    "name": "The ₿itcoin Lisa",
    "twitter": "@bitcoin_lisa",
    "desc": "#Bitcoin #nostr: npub1y9fddfevrv8fq0hhgy7t88cyzxd08zcawwcg5cn7h5f4p2acmd3qmwlrax",
    "npub": "npub1y9fddfevrv8fq0hhgy7t88cyzxd08zcawwcg5cn7h5f4p2acmd3qmwlrax",
    "languages": ["en"]
  },
  {
    "name": "GermanHODL ",
    "twitter": "@GermanHodl",
    "desc": "#Bitcoin Maximalist & Ancap - Defund the System #Club137 Nostr: npub1vpl8x2cj5ujdtcgjen2t5873nrzt2m0nepcn883qpyq6tgnd2cuqnxwu0p",
    "npub": "npub1vpl8x2cj5ujdtcgjen2t5873nrzt2m0nepcn883qpyq6tgnd2cuqnxwu0p",
    "languages": ["en"]
  },
  {
    "name": "yojoe",
    "twitter": "@_yojoe_",
    "desc": "low-time-preference observerrrrrrrrrrrrr nostr: npub1xf0yh88lmd86uu9eq7m7k59wsu37f2z7p7fm9hr7sl70duycnzjs5v0crs",
    "npub": "npub1xf0yh88lmd86uu9eq7m7k59wsu37f2z7p7fm9hr7sl70duycnzjs5v0crs",
    "languages": ["en"]
  },
  {
    "name": "Satonymous",
    "twitter": "@sat_std",
    "desc": "#bitcoin  #nostr: npub180ygqt0y478zj0xgxq0zrz7tc233y3q9ccysj5zlnqz0qnrfpqkqxrrxnc",
    "npub": "npub180ygqt0y478zj0xgxq0zrz7tc233y3q9ccysj5zlnqz0qnrfpqkqxrrxnc",
    "languages": ["en"]
  },
  {
    "name": "freegold (rickgold.dao)",
    "twitter": "@freegold",
    "desc": "Activist, Author, Writer, Online NetWorker, Crypto Enthusiast My nft on the Polygon Network ... http://polygonscan.com/token/0x72b6dc… #Nostr: npub1jnn5physre5cf8tmpdz",
    "npub": "npub1jnn5physre5cf8tmpdz",
    "languages": ["en"]
  },
  {
    "name": "Lucas",
    "twitter": "@lucashaley",
    "desc": "@lucashaley@mastodon.gamedev.place. Nostr: npub1kd0eeufryegpfvcusqpw0wktucjey8nmnq8735z6x5xfmdye4dlqq3en34",
    "npub": "npub1kd0eeufryegpfvcusqpw0wktucjey8nmnq8735z6x5xfmdye4dlqq3en34",
    "languages": ["en"]
  },
  {
    "name": "kurtpan.eth",
    "twitter": "@kurtpan666",
    "desc": "Nostr: npub14eqydcp9kcxfps6uaq6nnqa4fs984ah0yqym4uduu6whmjral3wqkn5nx2 221125已退推，朋友们江湖再见：http://crypto.kurtpan.pro",
    "npub": "npub14eqydcp9kcxfps6uaq6nnqa4fs984ah0yqym4uduu6whmjral3wqkn5nx2",
    "languages": ["en"]
  },
  {
    "name": "Kyle LeBlanc",
    "twitter": "@KylePLeBlanc",
    "desc": "#nostr: npub1kmzhptztnu6h8f0nyhj37gzqcsuq698fu0kttfpcz9dgc6rl5twsafsy2g",
    "npub": "npub1kmzhptztnu6h8f0nyhj37gzqcsuq698fu0kttfpcz9dgc6rl5twsafsy2g",
    "languages": ["en"]
  },
  {
    "name": "lxx (, )",
    "twitter": "@luoxiaxia",
    "desc": "Whatever happens, happens... Don't cancel me. ₿ 人類躺平計劃 #Bitcoin $people nostr: npub1hs6vekvwp44uv8mhgcjnr2ff7nltr5lrzjmff3a3eeyeyk4pvpuqr6n275",
    "npub": "npub1hs6vekvwp44uv8mhgcjnr2ff7nltr5lrzjmff3a3eeyeyk4pvpuqr6n275",
    "languages": ["en"]
  },
  {
    "name": "Jim Olenbush",
    "twitter": "@jimolenbush",
    "desc": "#RealEstate and #Bitcoin | http://AustinRealEstate.com #Nostr npub15mqjymffr9uudc4a2a02f9cg0fhncyjxd72rzvwxu57hhzw2r3dq7u6uzl",
    "npub": "npub15mqjymffr9uudc4a2a02f9cg0fhncyjxd72rzvwxu57hhzw2r3dq7u6uzl",
    "languages": ["en"]
  },
  {
    "name": " Слава Україні!  | MiCHΛΞl",
    "twitter": "@michaelhenke",
    "desc": "Diplominformatiker, im Internet zu Hause seit 1999, lebe und arbeite in Kalifornien, #nostr: npub1arnmuwrgtvv0p7gnydr9489czhj5zjnzjz9gx7x4wcvtr0u92das76vlnd",
    "npub": "npub1arnmuwrgtvv0p7gnydr9489czhj5zjnzjz9gx7x4wcvtr0u92das76vlnd",
    "languages": ["en"]
  },
  {
    "name": "Gloire Wanzavalere",
    "twitter": "@GloireKW",
    "desc": "Co-founder  @Afrobitcoinorg  &  @Kiveclair  Nostr : npub1dj9qzdp6z3ae39tpgklusq0t7lnnl6vjmcyc9j9l7lsuh37767hqs96fyt",
    "npub": "npub1dj9qzdp6z3ae39tpgklusq0t7lnnl6vjmcyc9j9l7lsuh37767hqs96fyt",
    "languages": ["en"]
  },
  {
    "name": "sabalay",
    "twitter": "@sabbxy",
    "desc": "Exploring my curiosity. #Nostr: npub1vmqqazk4m7n0x4uvn28f920ruym3d27s090r63uy4dmjsq5jmyqsq9t335",
    "npub": "npub1vmqqazk4m7n0x4uvn28f920ruym3d27s090r63uy4dmjsq5jmyqsq9t335",
    "languages": ["en"]
  },
  {
    "name": "dutchy",
    "twitter": "@dutchymitts",
    "desc": "A pleb looking out for my herd. Fiat day job in the MeV electromagnetic spectrum. NOSTR: npub10yayg2amryy3rjh0af8sz0jluqfc2wnfx5hahc877ht86h3zmmvq3fjuf2",
    "npub": "npub10yayg2amryy3rjh0af8sz0jluqfc2wnfx5hahc877ht86h3zmmvq3fjuf2",
    "languages": ["en"]
  },
  {
    "name": "Gilang Arenza ",
    "twitter": "@_glgg",
    "desc": "Catur Warga  Urip iku kudu sat set sat set, taz tez taz tez. nostr: #npub1eqhx4pej6tgx8tcw264ugpwsgx3czkfvpskaxas2atf9eg584h5qld043a",
    "npub": "npub1eqhx4pej6tgx8tcw264ugpwsgx3czkfvpskaxas2atf9eg584h5qld043a",
    "languages": ["en"]
  },
  {
    "name": "Kurihei : くりへい",
    "twitter": "@kurihei",
    "desc": "やるしかないのだ 創る人 つぶやきは適当なので信じないように。 http://k2-garage.com/saito/Product.… Nostr: npub1lm6m7jmsdjzazklxh3zgztzl6gvwx74quezjd4n50dr72pl82gjsxfdpmr",
    "npub": "npub1lm6m7jmsdjzazklxh3zgztzl6gvwx74quezjd4n50dr72pl82gjsxfdpmr",
    "languages": ["en"]
  },
  {
    "name": "unhosted",
    "twitter": "@_unhosted",
    "desc": " now@getalby.com nostr npub1eps3783d3tkkka8e9x4u80g5cl35uf476z7w596hnk2x8pqs938qu072uy",
    "npub": "npub1eps3783d3tkkka8e9x4u80g5cl35uf476z7w596hnk2x8pqs938qu072uy",
    "languages": ["en"]
  },
  {
    "name": "Yesh",
    "twitter": "@YeshodharaB",
    "desc": "PM | Ops - Helping humanity Web3 - DeSci ( @SCRForum ) | Effective Altruism | Nostr @ npub1q92c9hxh0n2jjftzxmx4n02l3tswssey9awmn5cp4hyp075rhmgswgwz5n",
    "npub": "npub1q92c9hxh0n2jjftzxmx4n02l3tswssey9awmn5cp4hyp075rhmgswgwz5n",
    "languages": ["en"]
  },
  {
    "name": "CJ",
    "twitter": "@C2DaJMan",
    "desc": "₿itcoin and Fungi!  Nostr: npub10xn5xrqght380v5v7wdyfg5teak0k0gqx9uweurdttn09q57nezqqkwf7n",
    "npub": "npub10xn5xrqght380v5v7wdyfg5teak0k0gqx9uweurdttn09q57nezqqkwf7n",
    "languages": ["en"]
  },
  {
    "name": "Juan Reyes",
    "twitter": "@Juan_Reye1",
    "desc": "The invisible hand is so invisible, no one has ever seen it.  @stompslumlords   #Nostr: npub17frjsd2nu40zshledyeqavvn42uvrz8fm2pnw47cslj38e06dkpsrmk6kn",
    "npub": "npub17frjsd2nu40zshledyeqavvn42uvrz8fm2pnw47cslj38e06dkpsrmk6kn",
    "languages": ["en"]
  },
  {
    "name": "Dutra",
    "twitter": "@dutradotdev",
    "desc": "nao espere nada de útil saindo daqui nostr npub12av34w4tlkamljuykvf3ueg4dmc3ch22xa9qcnng0qnxu3ggc2csarcm5v",
    "npub": "npub12av34w4tlkamljuykvf3ueg4dmc3ch22xa9qcnng0qnxu3ggc2csarcm5v",
    "languages": ["en"]
  },
  {
    "name": "Jeffry ",
    "twitter": "@JefriNewbie",
    "desc": "#Bitcoin #Binance Nostr : #npub1gdws4h06q3tnw9uldukkh2t44neu2umggkuh7p2qd0sme2dnhdds5cm3zh",
    "npub": "npub1gdws4h06q3tnw9uldukkh2t44neu2umggkuh7p2qd0sme2dnhdds5cm3zh",
    "languages": ["en"]
  },
  {
    "name": "Ademar",
    "twitter": "@ademar111190",
    "desc": "Helping the lightning network be accessible for everyone with  @Breez_Tech  𐤓𐤀𐤌𐤄𐤃𐤀 Nostr: npub1yv26qmfntfj2ukxx9pc3fghexm4yfjjl9f7vxhc8j7hvhczt879qnnx8q9",
    "npub": "npub1yv26qmfntfj2ukxx9pc3fghexm4yfjjl9f7vxhc8j7hvhczt879qnnx8q9",
    "languages": ["en"]
  },
  {
    "name": "Brandon Nakamoto",
    "twitter": "@BrandonNakamoto",
    "desc": "Nostr: npub1zqnx5d3np42e298luqqvumr7shcmkcdn8vxewx4nmkpc89d33hfsfx4tjx LN Tips: brandonnakamoto@getalby.com",
    "npub": "npub1zqnx5d3np42e298luqqvumr7shcmkcdn8vxewx4nmkpc89d33hfsfx4tjx",
    "languages": ["en"]
  },
  {
    "name": "Lars Gregori - @choas@mastodon.social",
    "twitter": "@choas",
    "desc": "@choas@mastodon.social #nostr: npub17yykewvd2stqwehfku0538jhsh9wvsplr9fn9qq92qkdycll3v9s8a0tn2",
    "npub": "npub17yykewvd2stqwehfku0538jhsh9wvsplr9fn9qq92qkdycll3v9s8a0tn2",
    "languages": ["en"]
  },
  {
    "name": "Avinash Burra",
    "twitter": "@AvinashBurra",
    "desc": "Bitcoin Surrealist | Nostr: npub1hqaz3dlyuhfqhktqchawke39l92jj9nt30dsgh2zvd9z7dv3j3gqpkt56s",
    "npub": "npub1hqaz3dlyuhfqhktqchawke39l92jj9nt30dsgh2zvd9z7dv3j3gqpkt56s",
    "languages": ["en"]
  },
  {
    "name": "Morty | Buji DAO",
    "twitter": "@Morty0x",
    "desc": "Shitposting sometimes, Profit Maxi #Crypto #BUIDL #AlgoTrading |  @buji_dao  #nostr npub1g7dlhfr40j68u0f9ee88k52mk6wvakct93xnfuur8et5qfynkxjs63yv88",
    "npub": "npub1g7dlhfr40j68u0f9ee88k52mk6wvakct93xnfuur8et5qfynkxjs63yv88",
    "languages": ["en"]
  },
  {
    "name": "Gu₿i",
    "twitter": "@GubiArt",
    "desc": "#art #bitcoin and more #nostr npub1gpppr3hfwcl5njxqmp3zumaly9j4pd6gvapywsq2y5n7p7278fus6nz7l5",
    "npub": "npub1gpppr3hfwcl5njxqmp3zumaly9j4pd6gvapywsq2y5n7p7278fus6nz7l5",
    "languages": ["en"]
  },
  {
    "name": "iamnomoney.eth",
    "twitter": "@iamnomon",
    "desc": "scientist. nostr: npub1s2r973a599gqqfktpg0u9824f0n5emytycdkv3sa5dwax52xnfuqkejh5f",
    "npub": "npub1s2r973a599gqqfktpg0u9824f0n5emytycdkv3sa5dwax52xnfuqkejh5f",
    "languages": ["en"]
  },
  {
    "name": "lightningdav",
    "twitter": "@lightningdav",
    "desc": "#Bitcoin, don’t trust, VERIFY. Views are my own, all tweets are my own. #nostr: npub17082grwhfzh5q9vlxuk6q79t8etd0xx78t34q9hyc45z5m48z5j",
    "npub": "npub17082grwhfzh5q9vlxuk6q79t8etd0xx78t34q9hyc45z5m48z5j",
    "languages": ["en"]
  },
  {
    "name": "LF ",
    "twitter": "@Louferlou",
    "desc": "#Bitcoin - Freedom - http://btc-whitepaper.com |louferlou@getalby.com | #nostr: npub1psjwxg6j9re7u5mf6j52gm4f9u50r2pexm0xrtrtgqpagq6y6s3qhfa504",
    "npub": "npub1psjwxg6j9re7u5mf6j52gm4f9u50r2pexm0xrtrtgqpagq6y6s3qhfa504",
    "languages": ["en"]
  },
  {
    "name": "Jennifer",
    "twitter": "@jnelson712",
    "desc": "Relentlessly Optimistic Nostr: npub1krrp9svgcwt3002gyrah0mh0ssvxk0y0xt8wgqggsm8he89y7whsz6ptt4",
    "npub": "npub1krrp9svgcwt3002gyrah0mh0ssvxk0y0xt8wgqggsm8he89y7whsz6ptt4",
    "languages": ["en"]
  },
  {
    "name": "Selcuk",
    "twitter": "@selcuksirav",
    "desc": "_________ #nostr: #npub1wvxmfz7ztwelsqt264gl90lgdwd0sq85a2utgjrqnjk5wlh49ryqc053dj",
    "npub": "npub1wvxmfz7ztwelsqt264gl90lgdwd0sq85a2utgjrqnjk5wlh49ryqc053dj",
    "languages": ["en"]
  },
  {
    "name": "Mike",
    "twitter": "@m1k__3",
    "desc": "#bitcoin #nostr: npub12tmvszer5zq8adgzeyw797kvhqsvdl8n8ajvsp8suq6ml5wtr86sfm8qh0",
    "npub": "npub12tmvszer5zq8adgzeyw797kvhqsvdl8n8ajvsp8suq6ml5wtr86sfm8qh0",
    "languages": ["en"]
  },
  {
    "name": "Wild ₿",
    "twitter": "@WildB19",
    "desc": "#Bitcoin self-custody. #nostr: npub197p8f7q8zt5fkxq79jerys7ytmvdmk7cp8uwcxazk62fmgtumv3sw6vztu",
    "npub": "npub197p8f7q8zt5fkxq79jerys7ytmvdmk7cp8uwcxazk62fmgtumv3sw6vztu",
    "languages": ["en"]
  },
  {
    "name": "crypto投資家",
    "twitter": "@positivebitcoin",
    "desc": "Bitcoin,Binance,FindSatoshiLabまわりをメインに追いかけてる投資家(保有 #BTC #BNB #GMT) ビットコイナー用#nostr npub1wtdevzfrqx26k4us6wmvhqrju5w3zx3tk2xp5eut597nsdlz2wkq4t93ds",
    "npub": "npub1wtdevzfrqx26k4us6wmvhqrju5w3zx3tk2xp5eut597nsdlz2wkq4t93ds",
    "languages": ["en"]
  },
  {
    "name": "絢香猫",
    "twitter": "@ayakaneko",
    "desc": "称呼我 Neko 就好 |   @OikawaRizumu  | Nostr: npub1pnnf40sq3tjz8ljsmd5rek6xctx689pcv3yx40g5wdrefdqude0se7rupe",
    "npub": "npub1pnnf40sq3tjz8ljsmd5rek6xctx689pcv3yx40g5wdrefdqude0se7rupe",
    "languages": ["en"]
  },
  {
    "name": "Christian Aranha",
    "twitter": "@cnaranhaoficial",
    "desc": "Autor do livro Bitcoin, Blockchain e Muito Dinheiro Nostr npub1w28tdn7g2tg35uywjpk8y9n0dena3vs3jtjsj899wjzt0hwhxhus3jsgd3",
    "npub": "npub1w28tdn7g2tg35uywjpk8y9n0dena3vs3jtjsj899wjzt0hwhxhus3jsgd3",
    "languages": ["en"]
  },
  {
    "name": "スマートコン",
    "twitter": "@mr_konn",
    "desc": "小人閑居為不貞寝 keybase: https://keybase.io/konn nostr: https://damus.io/npub1thlw8jw45f4etn3ec746x6lt7aj7vvsdwhv9t52f28w4r5q9mxdquyspj7… mastodon: @konn@mstdn.jp",
    "npub": "npub1thlw8jw45f4etn3ec746x6lt7aj7vvsdwhv9t52f28w4r5q9mxdquyspj7",
    "languages": ["en"]
  },
  {
    "name": "Jazarønaut ",
    "twitter": "@Jazaronaut",
    "desc": "Sat/Sats  - Tick Tock Next Block - #nostr: npub1dtgtdd8acd7fmluzrh3svresjj6h480qrzn2zeudyg8meqx0e2wq8hdxjg",
    "npub": "npub1dtgtdd8acd7fmluzrh3svresjj6h480qrzn2zeudyg8meqx0e2wq8hdxjg",
    "languages": ["en"]
  },
  {
    "name": "Akro { ₿  }",
    "twitter": "@AkroCrypto",
    "desc": "#Bitcoin #nostr npub1q9xmmvq2a2c670h9jn6f67v8j2nc4gw4a2u92rt9n5z223tg84mq52a38v",
    "npub": "npub1q9xmmvq2a2c670h9jn6f67v8j2nc4gw4a2u92rt9n5z223tg84mq52a38v",
    "languages": ["en"]
  },
  {
    "name": "望京欧弟",
    "twitter": "@yoyicue",
    "desc": "nostr: npub127k2jy3t24m9cx5m7p8024yca9rtweyjgr7xt9xqwn2p978c7krqyx89qu",
    "npub": "npub127k2jy3t24m9cx5m7p8024yca9rtweyjgr7xt9xqwn2p978c7krqyx89qu",
    "languages": ["en"]
  },
  {
    "name": "Cat SΞrious!",
    "twitter": "@blurvision_eth",
    "desc": "Daytrader / https://marketciphertrading.com/?ref=23841 #nostr : npub14tmd6pn8gn2t8wal8tluxz9nce9k2jvtlda6mkj4nzuemls9ksxq4gaql9",
    "npub": "npub14tmd6pn8gn2t8wal8tluxz9nce9k2jvtlda6mkj4nzuemls9ksxq4gaql9",
    "languages": ["en"]
  },
  {
    "name": "Jean-Pierre Trophardy",
    "twitter": "@jptrophardy",
    "desc": "@nostr  pubkey npub1v6gl8aljmexhn7jxjc9tkz8fmj3g9lglrw6gaywr8knu53xuhp6qkgg32f",
    "npub": "npub1v6gl8aljmexhn7jxjc9tkz8fmj3g9lglrw6gaywr8knu53xuhp6qkgg32f",
    "languages": ["en"]
  },
  {
    "name": "bemike",
    "twitter": "@bemike",
    "desc": "A Nice Guy. 自留地：https://t.me/lifesmalltalks Nostr: npub1gzz3pvlvk6wk2k9lnlkw96rzxqp5xr99qftv2njppyv29c9u4nuqnw9393",
    "npub": "npub1gzz3pvlvk6wk2k9lnlkw96rzxqp5xr99qftv2njppyv29c9u4nuqnw9393",
    "languages": ["en"]
  },
  {
    "name": "BlackNoise",
    "twitter": "@Zealot_0x",
    "desc": "Crypto Data Exploiter Nostr: npub17mlsydd7j7q9n24zalyscqs5vcnjyvs04w2e5047cm04ktjfwghq693424 #BTC #ETH #CRYPTOART",
    "npub": "npub17mlsydd7j7q9n24zalyscqs5vcnjyvs04w2e5047cm04ktjfwghq693424",
    "languages": ["en"]
  },
  {
    "name": "Michael Lirio",
    "twitter": "@MyCoinThoughts",
    "desc": "#Bitcoin is the successful creation of engineered money. Focused on signal. #nostr: npub1jz98mqsrylvmfrfknqxc7m3fh7thxygfsuvdqj22y0h5mmxdaetqdkpv63",
    "npub": "npub1jz98mqsrylvmfrfknqxc7m3fh7thxygfsuvdqj22y0h5mmxdaetqdkpv63",
    "languages": ["en"]
  },
  {
    "name": "Þór Adam",
    "twitter": "@thoradam",
    "desc": "I like energy drinks, Marvel movies, nu metal and weightlifting. Programmer @ http://indo.is. #nostr: npub1wm8hv3hx2qdne4wwhalkjd2qttursjctxcgwv20ujvcpjsyadzxsc2ly06",
    "npub": "npub1wm8hv3hx2qdne4wwhalkjd2qttursjctxcgwv20ujvcpjsyadzxsc2ly06",
    "languages": ["en"]
  },
  {
    "name": "July",
    "twitter": "@ph1x",
    "desc": "#nostr: npub1w9j533nceku2pvv0kc9tq45zwexwp8wd75etz400x6elar5vp6qqfkc8pq",
    "npub": "npub1w9j533nceku2pvv0kc9tq45zwexwp8wd75etz400x6elar5vp6qqfkc8pq",
    "languages": ["en"]
  },
  {
    "name": "Mike Williams",
    "twitter": "@mikethough",
    "desc": "CTO - Developer - Automator - Generalist - Founder  @buildlabdigital . Nostr Verification: npub1k86anutkqszjqz8tv4zu9curnqw2p0qz7zfes6zc8fcfdjzgzukslph7gp",
    "npub": "npub1k86anutkqszjqz8tv4zu9curnqw2p0qz7zfes6zc8fcfdjzgzukslph7gp",
    "languages": ["en"]
  },
  {
    "name": "ちょめじ",
    "twitter": "@ch0meji",
    "desc": "暗号資産で支払えるキッチンカーをやってたりします。 モナコインとかビットコインとか 所有・交換可能なモナカード一覧は下のリンクから見てね！趣味は暗号資産/自転車/UO/バイク/MTG・ポケモンカード集め/釣り/Nostr:npub17y0fr3dlfphmewfsmq5pr5pxk/ch0meji.eth",
    "npub": "npub17y0fr3dlfphmewfsmq5pr5pxk",
    "languages": ["en"]
  },
  {
    "name": "𝘅𝗶𝗻𝘇𝗵𝗶 ",
    "twitter": "@xinzhi",
    "desc": "Building http://useApp.me, write code & posts  @Workbene , also http://youtube.com/@xinzhixiang #Nostr npub1lzjz5ru4mqaj7hr0n48a242sj9vwy8hy6t68kxaawmqu0s0ssdzqlxxzcc",
    "npub": "npub1lzjz5ru4mqaj7hr0n48a242sj9vwy8hy6t68kxaawmqu0s0ssdzqlxxzcc",
    "languages": ["en"]
  },
  {
    "name": "Carlos De Armas",
    "twitter": "@cdearmasch",
    "desc": "Infectologo | Ciclista Master | GYM | Economía | Maximalista #Bitcoin #nostr npub1adwcrl3m5xka7qkx3fgrewzam7hnjttqhnqz84l9nrrnuc0clpfqu577ec",
    "npub": "npub1adwcrl3m5xka7qkx3fgrewzam7hnjttqhnqz84l9nrrnuc0clpfqu577ec",
    "languages": ["en"]
  },
  {
    "name": "k:NFT Collector & DeFi + NODE Trader ",
    "twitter": "@tcrpty",
    "desc": "$DeFi and $NFTs change the world!! #CakedApes fam #Node trader #Nostr npub1rlzmcejr60gfk9qyruqazs7v6sevpsuhdjszz9dtjev9ulu0jxwqxsycfr",
    "npub": "npub1rlzmcejr60gfk9qyruqazs7v6sevpsuhdjszz9dtjev9ulu0jxwqxsycfr",
    "languages": ["en"]
  },
  {
    "name": "hongjiawei.eth/tez",
    "twitter": "@Jhong3_",
    "desc": "Cats/Crypto/PE Fund Admin for  @Statestreet  Selling ENS domains: nftsla.eth #Bitcoin #nostr npub124n2s3ts457ek8f35d6syp9vf8rj8s5pg009h0dzw8f3x5akxltqwwfmqg",
    "npub": "npub124n2s3ts457ek8f35d6syp9vf8rj8s5pg009h0dzw8f3x5akxltqwwfmqg",
    "languages": ["en"]
  },
  {
    "name": "ryu",
    "twitter": "@ryu",
    "desc": "#nostr @ pubkey: npub15njg39fpt7v48yjm4xv4heqj3qhsgchqp6tkgzvu6calt3svl2gqvvpq5g",
    "npub": "npub15njg39fpt7v48yjm4xv4heqj3qhsgchqp6tkgzvu6calt3svl2gqvvpq5g",
    "languages": ["en"]
  },
  {
    "name": "Ecologia Digital",
    "twitter": "@josemurilo",
    "desc": "Notas sobre o equilíbrio nas conexões entre pessoas e instituições no ambiente digital #Nostr: npub18ggcr98zm5unynyfg7sjyqstfuclartwhwyrr79harevmknkt8vs4xvkgn",
    "npub": "npub18ggcr98zm5unynyfg7sjyqstfuclartwhwyrr79harevmknkt8vs4xvkgn",
    "languages": ["en"]
  },
  {
    "name": "2A3DEX",
    "twitter": "@2a3dex",
    "desc": "#bitcoin miner and node operator | PGP: 7B28 DF7C 18A3 3E25 | Nostr: npub1qsn7svsymmwxcldznkmdj7nwsyrzqmmnvxt5d02e8klevhawv0jsjn6fyq",
    "npub": "npub1qsn7svsymmwxcldznkmdj7nwsyrzqmmnvxt5d02e8klevhawv0jsjn6fyq",
    "languages": ["en"]
  },
  {
    "name": "MoreCredible",
    "twitter": "@MoreCredible",
    "desc": "#Bitcoin & Florida Gators Maximalist. I’m not here to help, I’m here to laugh. Nostr: npub1jwqcdljz6qcsdjrasjc54xcyy2xj0dxqc84f6r29sjva4dcqwntqwhqhwg",
    "npub": "npub1jwqcdljz6qcsdjrasjc54xcyy2xj0dxqc84f6r29sjva4dcqwntqwhqhwg",
    "languages": ["en"]
  },
  {
    "name": "nybe",
    "twitter": "@nybe",
    "desc": "ᴄʀᴇᴀᴛɪᴠᴇ ᴏᴘᴇʀᴀᴛɪᴠᴇ | #bitcoin ‘11 | here for the hyperbole | on #nostr: #npub1ss8e6s2lufl9twvfzwk9uwcsd45yglyy4p44kzu3m6u2aw4rtwuquvu3mz",
    "npub": "npub1ss8e6s2lufl9twvfzwk9uwcsd45yglyy4p44kzu3m6u2aw4rtwuquvu3mz",
    "languages": ["en"]
  },
  {
    "name": "@fredix@mamot.fr",
    "twitter": "@fredix",
    "desc": "@fredix@mamot.fr #nostr : npub1vwldp9k95xxe9mgnzakykpmhmazj5q2veldt6jvju2dvrfkxvlaq6dzfhz",
    "npub": "npub1vwldp9k95xxe9mgnzakykpmhmazj5q2veldt6jvju2dvrfkxvlaq6dzfhz",
    "languages": ["en"]
  },
  {
    "name": "Bitcoin Puerto Rico ",
    "twitter": "@BTCPuertoRico",
    "desc": "npub1ef5k6lkmf2rtulranpet6tu6g3zqe78zmeu9x5mvhvaxpt5fvs0s3lyzzu",
    "npub": "npub1ef5k6lkmf2rtulranpet6tu6g3zqe78zmeu9x5mvhvaxpt5fvs0s3lyzzu",
    "languages": ["en"]
  },
  {
    "name": "James Leopold ",
    "twitter": "@jleophodl",
    "desc": "Unshakeable #Bitcoin hodler No coin #nostr: npub1lzljdvwfr7vapzskqxxmwlg6xnh0ervvumq4ejlyhzrvustu452qg3fcg2 ",
    "npub": "npub1lzljdvwfr7vapzskqxxmwlg6xnh0ervvumq4ejlyhzrvustu452qg3fcg2",
    "languages": ["en"]
  },
  {
    "name": "And thus the 118th Sh!tshow has commenced.",
    "twitter": "@823labs",
    "desc": "\"When stupidity is considered patriotism, it is unsafe to be intelligent.\" - Isaac Asimov Nostr: npub1h3u872w5un6mx07wl92zr76m9q7rkehyx3278g6e8k62dalxznyqrwr7v8",
    "npub": "npub1h3u872w5un6mx07wl92zr76m9q7rkehyx3278g6e8k62dalxznyqrwr7v8",
    "languages": ["en"]
  },
  {
    "name": "Mish",
    "twitter": "@M1shaal",
    "desc": "late bloomer | #Bitcoin | #nostr npub1nh3p45mg6a0r6pnwgn0j7frmwsdrama0mmmmqcawaz0p0rscnhgskr0fcr",
    "npub": "npub1nh3p45mg6a0r6pnwgn0j7frmwsdrama0mmmmqcawaz0p0rscnhgskr0fcr",
    "languages": ["en"]
  },
  {
    "name": "Bitcoin or Slavery",
    "twitter": "@btcorslavery",
    "desc": "#Bitcoin Maximalist, Cypherpunk, Sovereignty, Libertarian, Austrian Economic, Anti-Woke. Nostr #npub1xq9dupz0srj2nn98p9dy325wfret22z52gruaqdr35rf37fjdx4spyuqaz",
    "npub": "npub1xq9dupz0srj2nn98p9dy325wfret22z52gruaqdr35rf37fjdx4spyuqaz",
    "languages": ["en"]
  },
  {
    "name": "Daniel_Buhl  ∞/21M",
    "twitter": "@Daniel_Buhl_sh",
    "desc": "Dies ist der offizielle Twitter-Account von Daniel Buhl #Bitcoin #lightning #NOSTR npub12hhpljjshyz34t94tax4a8rs6m28ha7v0n0r2aszvwqy6dxapccsk8k0t0",
    "npub": "npub12hhpljjshyz34t94tax4a8rs6m28ha7v0n0r2aszvwqy6dxapccsk8k0t0",
    "languages": ["en"]
  },
  {
    "name": "Aaron Foster",
    "twitter": "@aaron__foster",
    "desc": "@LuxorTechTeam . Previously Ontario Power Generation Nuclear Nostr: npub1c4y2chnm3e77cl2ch47ccz8leekqlgkan4kt7nx6wm0nsuww4mdqw0hm5h",
    "npub": "npub1c4y2chnm3e77cl2ch47ccz8leekqlgkan4kt7nx6wm0nsuww4mdqw0hm5h",
    "languages": ["en"]
  },
  {
    "name": "Brandon H. Gomes",
    "twitter": "@brandonhgomes",
    "desc": "CTO  @mantanetwork  &  @p0xLabs  | zk is cool | #nostr: npub1pepcarz4t95rcey3yue5v7vu6hzvssad6z4jgxwnz7mmm8kr8m4swjngpj",
    "npub": "npub1pepcarz4t95rcey3yue5v7vu6hzvssad6z4jgxwnz7mmm8kr8m4swjngpj",
    "languages": ["en"]
  },
  {
    "name": "이름뭐하지",
    "twitter": "@nameEO",
    "desc": "@naeoEM  / http://peing.net/ko/nameeo, http://curiouscat.me/nameEO, #nostr: npub1nameus4a5uruxle552n7ryn23sj5hn94ql503vn2dx86d9uh0zrqqu6t5k",
    "npub": "npub1nameus4a5uruxle552n7ryn23sj5hn94ql503vn2dx86d9uh0zrqqu6t5k",
    "languages": ["en"]
  },
  {
    "name": "Deji",
    "twitter": "@DejiMarquis",
    "desc": "Having fun. Nostr: npub1n47xp29lyq8g9nwr6afdt6ywwzldguwj8ls7ac9nw6ghugzz76rss27p23",
    "npub": "npub1n47xp29lyq8g9nwr6afdt6ywwzldguwj8ls7ac9nw6ghugzz76rss27p23",
    "languages": ["en"]
  },
  {
    "name": "SΞAN",
    "twitter": "@sean4269",
    "desc": "Buidling infrastructures for onboarding the next billion users #mfers #eip4337 #bitcoin #nostr: npub1ycssvrxjyfqlgsugwpwkxzz3xtds9nfth573jf87z3qf5sclhd3qzwmedf",
    "npub": "npub1ycssvrxjyfqlgsugwpwkxzz3xtds9nfth573jf87z3qf5sclhd3qzwmedf",
    "languages": ["en"]
  },
  {
    "name": "jaap den dulk",
    "twitter": "@dulk",
    "desc": "IamEuropean - creative license - LEGO® Serious Play® - marketing @dulk@mastodon.social #Nostr: #npub14kreecwvl6jlmgsz67skykd30jh07lxd5mgyldkg60c7s2n3sagsz6yesz",
    "npub": "npub14kreecwvl6jlmgsz67skykd30jh07lxd5mgyldkg60c7s2n3sagsz6yesz",
    "languages": ["en"]
  },
  {
    "name": "xnerox ",
    "twitter": "@xneroxbitcoin",
    "desc": "#bitcoin #Nostr npub1094xmx7nwvn6f669qelyqarlz00nfat4l9lftv5tk8t46wa4x03s7thwvn",
    "npub": "npub1094xmx7nwvn6f669qelyqarlz00nfat4l9lftv5tk8t46wa4x03s7thwvn",
    "languages": ["en"]
  },
  {
    "name": "StackMoarrr",
    "twitter": "@1stRdK0",
    "desc": "#Bitcoin Only  - Sats, Whiskey & BBQ NOSTR: npub1ny79nn3n44rm2v60y0a2swx3k9pxw57padg8setyvx6v43vhphhs5qn4x2",
    "npub": "npub1ny79nn3n44rm2v60y0a2swx3k9pxw57padg8setyvx6v43vhphhs5qn4x2",
    "languages": ["en"]
  },
  {
    "name": "jeremey",
    "twitter": "@veganXpower",
    "desc": "XVX | #btc| https://badbreakrecords.com | nostr: npub13yr23tfvxkvqanw7s7hta6u0rlyaaw2y8em9vnvm4marq3vekaqqxe050e",
    "npub": "npub13yr23tfvxkvqanw7s7hta6u0rlyaaw2y8em9vnvm4marq3vekaqqxe050e",
    "languages": ["en"]
  },
  {
    "name": "JPaq",
    "twitter": "@JPaq_6",
    "desc": "#Bitcoin #nostr npub1hvvf3yzew9ztv9tva4c8dpyjjnf4tl2wwwlmuslt3w0zhkfuxxpq58ad4p",
    "npub": "npub1hvvf3yzew9ztv9tva4c8dpyjjnf4tl2wwwlmuslt3w0zhkfuxxpq58ad4p",
    "languages": ["en"]
  }
]
