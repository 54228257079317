import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite';
const AvatarGroup = ({ children, className, theme: customTheme = {}, ...props }) => {
    const theme = mergeDeep(useTheme().theme.avatarGroup.root, customTheme);
    return (_jsx("div", { "data-testid": "avatar-group-element", className: classNames(theme.base, className), ...props, children: children }));
};
AvatarGroup.displayName = 'Avatar.Group';
export default AvatarGroup;
