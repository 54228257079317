import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import { useTheme } from '../Flowbite/ThemeContext';
import { HelperText } from '../HelperText';
export const Textarea = forwardRef(({ shadow, helperText, color = 'gray', className, ...props }, ref) => {
    const theme = useTheme().theme.textarea;
    return (_jsxs(_Fragment, { children: [_jsx("textarea", { ref: ref, className: classNames(theme.base, theme.colors[color], theme.withShadow[shadow ? 'on' : 'off'], className), ...props }), helperText && _jsx(HelperText, { color: color, children: helperText })] }));
});
Textarea.displayName = 'Textarea';
