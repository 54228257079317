import NPubCard from "../NPListPage/NPubCard";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function ContactPage() {
  return (
    <div className="mx-10 p-10 flex justify-center">
      <NPubCard
        name="carl"
        twitter="@lctomlee"
        npub="npub10qqd4j6g9qtl7vcrp45563xufjy20esmlq3vgydny09d3fepglhqltnmh4"
      ></NPubCard>
    </div>
  );
}
