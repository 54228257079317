import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from 'react';
import windowExists from '../../helpers/window-exists';
import defaultTheme from '../../theme/default';
export const ThemeContext = createContext({
    theme: defaultTheme,
});
export const ThemeProvider = ({ children, value }) => {
    return _jsx(ThemeContext.Provider, { value: value, children: children });
};
export function useTheme() {
    return useContext(ThemeContext);
}
export const useThemeMode = (usePreferences) => {
    const [mode, setModeState] = useState('light');
    const savePreference = (mode) => localStorage.setItem('theme', mode);
    const getPreference = () => localStorage.getItem('theme') || getPrefersColorScheme();
    const userPreferenceIsDark = () => window.matchMedia?.('(prefers-color-scheme: dark)').matches;
    const getPrefersColorScheme = () => (userPreferenceIsDark() ? 'dark' : 'light');
    const toggleMode = () => {
        const newMode = mode === 'dark' ? 'light' : 'dark';
        setMode(newMode);
        setModeState(newMode);
    };
    const setMode = (mode) => {
        savePreference(mode);
        if (!windowExists()) {
            return;
        }
        if (mode === 'dark') {
            document.documentElement.classList.add('dark');
            return;
        }
        document.documentElement.classList.remove('dark');
    };
    if (usePreferences) {
        useEffect(() => setModeState(getPreference()), []);
        useEffect(() => setMode(mode), [mode]);
    }
    return [mode, setModeState, toggleMode];
};
