import { useState } from "react";
import { Link } from "react-router-dom";
import NPubCard from "./NPubCard";

type NPUser = {
  name: string;
  desc?: string;
  twitter?: string;
  languages: string[];
  npub: string;
};

export default function NPListPage() {
  let nplist: NPUser[] = require("./nplist.json");

  nplist = nplist.filter((np) => {
    return np.languages.includes(
      navigator.language.includes("zh") ? "zh" : "en"
    );
  });

  return (
    <div className="gap-5 flex-wrap">
      {nplist.map((np) => {
        return <NPubCard key={np.npub} {...np} />;
      })}
    </div>
  );
}
