import { useEffect } from 'react';
const useKeyDown = (key, callback) => {
    const handleKeyDown = (event) => {
        if (event.key === key) {
            callback();
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [key, callback]);
};
export default useKeyDown;
