import { useState } from "react";
import { Card, Tooltip } from "flowbite-react";
import { shrink } from "../../utils";
import NPubDisplay from "../../Component/NPubDisplay";
import { QRCodeSVG } from "qrcode.react";

export default function NPubCard({
  name,
  desc,
  twitter,
  npub,
}: {
  name: string;
  desc?: string;
  twitter?: string;
  npub: string;
}) {
  return (
    <Card className="lg:w-64 my-5">
      <div className="flex flex-col items-center pb-10 gap-4 p-4">
        <h5 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white">
          {name}
        </h5>
        {twitter && (
          <a
            className="text-gray-500 dark:text-gray-400 text-lg"
            target="_blank"
            href={`https://twitter.com/${twitter.substring(1)}`}
          >
            {twitter}
          </a>
        )}
        {desc && (
          <div className="text-lg text-gray-500 dark:text-gray-400 break-all">
            {desc}
          </div>
        )}
        <QRCodeSVG value={npub} size={200} />
        <NPubDisplay npub={npub} />
      </div>
    </Card>
  );
}
