import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
import { useAccordionContext } from './AccordionPanelContext';
export const AccordionContent = ({ children, className, theme: customTheme = {}, ...props }) => {
    const { isOpen } = useAccordionContext();
    const theme = mergeDeep(useTheme().theme.accordion.content, customTheme);
    return (_jsx("div", { className: classNames(theme.base, className), "data-testid": "flowbite-accordion-content", hidden: !isOpen, ...props, children: children }));
};
