import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef, useId } from 'react';
import { Badge } from '../Badge';
import { useTheme } from '../Flowbite/ThemeContext';
import { Tooltip } from '../Tooltip';
import { useSidebarContext } from './SidebarContext';
import { useSidebarItemContext } from './SidebarItemContext';
const ListItem = ({ id, isCollapsed, tooltipChildren, children: wrapperChildren, }) => (_jsx("li", { children: isCollapsed ? (_jsx(Tooltip, { content: _jsx(TooltipContent, { id: id, children: tooltipChildren }), placement: "right", children: wrapperChildren })) : (wrapperChildren) }));
const TooltipContent = ({ id, children }) => (_jsx(Children, { id: id, children: children }));
const Children = ({ id, children }) => {
    const theme = useTheme().theme.sidebar.item;
    return (_jsx("span", { className: classNames(theme.content.base), "data-testid": "flowbite-sidebar-item-content", id: `flowbite-sidebar-item-${id}`, children: children }));
};
const SidebarItem = forwardRef(({ as: Component = 'a', children, icon: Icon, active: isActive, label, labelColor = 'info', className, ...props }, ref) => {
    const id = useId();
    const { isCollapsed } = useSidebarContext();
    const { isInsideCollapse } = useSidebarItemContext();
    const theme = useTheme().theme.sidebar.item;
    return (_jsx(ListItem, { id: id, isCollapsed: isCollapsed, tooltipChildren: children, children: _jsxs(Component, { "aria-labelledby": `flowbite-sidebar-item-${id}`, className: classNames(theme.base, isActive && theme.active, !isCollapsed && isInsideCollapse && theme.collapsed.insideCollapse, className), ref: ref, ...props, children: [Icon && (_jsx(Icon, { "aria-hidden": true, className: classNames(theme.icon.base, isActive && theme.icon.active), "data-testid": "flowbite-sidebar-item-icon" })), isCollapsed && !Icon && (_jsx("span", { className: theme.collapsed.noIcon, children: children.charAt(0).toLocaleUpperCase() ?? '?' })), !isCollapsed && _jsx(Children, { id: id, children: children }), !isCollapsed && label && (_jsx(Badge, { color: labelColor, "data-testid": "flowbite-sidebar-label", hidden: isCollapsed, className: theme.label, children: label }))] }) }));
});
SidebarItem.displayName = 'Sidebar.Item';
export default SidebarItem;
