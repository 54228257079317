import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
import { HelperText } from '../HelperText';
export const FileInput = forwardRef(({ theme: customTheme = {}, sizing = 'md', helperText, color = 'gray', className, ...props }, ref) => {
    const theme = mergeDeep(useTheme().theme.fileInput, customTheme);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: classNames(theme.base, className), children: _jsx("div", { className: theme.field.base, children: _jsx("input", { className: classNames(theme.field.input.base, theme.field.input.colors[color], theme.field.input.sizes[sizing]), ...props, type: "file", ref: ref }) }) }), helperText && _jsx(HelperText, { color: color, children: helperText })] }));
});
FileInput.displayName = 'FileInput';
