import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTheme } from '../Flowbite/ThemeContext';
import { ListGroupItem } from './ListGroupItem';
const ListGroupComponent = ({ children, className, ...props }) => {
    const theme = useTheme().theme.listGroup.base;
    const theirProps = props;
    return (_jsx("ul", { className: classNames(theme, className), ...theirProps, children: children }));
};
ListGroupComponent.displayName = 'ListGroup';
ListGroupItem.displayName = 'ListGroup.Item';
export const ListGroup = Object.assign(ListGroupComponent, { Item: ListGroupItem });
