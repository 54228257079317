import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import { useTheme } from '../Flowbite/ThemeContext';
import ButtonGroup from './ButtonGroup';
const ButtonComponent = forwardRef(({ children, color = 'info', disabled = false, gradientDuoTone, gradientMonochrome, href, label, outline = false, pill = false, fullSized, positionInGroup = 'none', size = 'md', className, ...props }, ref) => {
    const isLink = typeof href !== 'undefined';
    const { buttonGroup: groupTheme, button: theme } = useTheme().theme;
    const Component = isLink ? 'a' : 'button';
    const theirProps = props;
    return (_jsx(Component, { className: classNames(disabled && theme.disabled, !gradientDuoTone && !gradientMonochrome && theme.color[color], gradientDuoTone && !gradientMonochrome && theme.gradientDuoTone[gradientDuoTone], !gradientDuoTone && gradientMonochrome && theme.gradient[gradientMonochrome], groupTheme.position[positionInGroup], outline && (theme.outline.color[color] ?? theme.outline.color.default), theme.base, theme.pill[pill ? 'on' : 'off'], fullSized && theme.fullSized, className), disabled: disabled, href: href, type: isLink ? undefined : 'button', ref: ref, ...theirProps, children: _jsx("span", { className: classNames(theme.inner.base, theme.inner.position[positionInGroup], theme.outline[outline ? 'on' : 'off'], theme.outline.pill[outline && pill ? 'on' : 'off'], theme.size[size], outline && !theme.outline.color[color] && theme.inner.outline), children: _jsxs(_Fragment, { children: [typeof children !== 'undefined' && children, typeof label !== 'undefined' && (_jsx("span", { className: theme.label, "data-testid": "flowbite-button-label", children: label }))] }) }) }));
});
ButtonComponent.displayName = 'Button';
export const Button = Object.assign(ButtonComponent, {
    Group: ButtonGroup,
});
