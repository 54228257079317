import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
import { useNavbarContext } from './NavbarContext';
export const NavbarCollapse = ({ theme: customTheme = {}, children, className, ...props }) => {
    const { isOpen } = useNavbarContext();
    const theme = mergeDeep(useTheme().theme.navbar.collapse, customTheme);
    return (_jsx("div", { className: classNames(theme.base, theme.hidden[!isOpen ? 'on' : 'off'], className), "data-testid": "flowbite-navbar-collapse", ...props, children: _jsx("ul", { className: theme.list, children: children }) }));
};
