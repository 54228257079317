import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { useEffect, useId, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { useTheme } from '../Flowbite/ThemeContext';
import { Tooltip } from '../Tooltip';
import { useSidebarContext } from './SidebarContext';
import { SidebarItemContext } from './SidebarItemContext';
const SidebarCollapse = ({ children, icon: Icon, label, className, open = false, ...props }) => {
    const id = useId();
    const { isCollapsed } = useSidebarContext();
    const [isOpen, setOpen] = useState(open);
    const theme = useTheme().theme.sidebar.collapse;
    useEffect(() => setOpen(open), [open]);
    const Wrapper = ({ children }) => (_jsx("li", { children: isCollapsed && !isOpen ? (_jsx(Tooltip, { content: label, placement: "right", children: children })) : (children) }));
    return (_jsxs(Wrapper, { children: [_jsxs("button", { className: classNames(theme.button, className), id: `flowbite-sidebar-collapse-${id}`, onClick: () => setOpen(!isOpen), title: label, type: "button", ...props, children: [Icon && (_jsx(Icon, { "aria-hidden": true, className: classNames(theme.icon.base, theme.icon.open[isOpen ? 'on' : 'off']), "data-testid": "flowbite-sidebar-collapse-icon" })), isCollapsed ? (_jsx("span", { className: "sr-only", children: label })) : (_jsxs(_Fragment, { children: [_jsx("span", { className: theme.label.base, "data-testid": "flowbite-sidebar-collapse-label", children: label }), _jsx(HiChevronDown, { "aria-hidden": true, className: theme.label.icon })] }))] }), _jsx("ul", { "aria-labelledby": `flowbite-sidebar-collapse-${id}`, className: theme.list, hidden: !isOpen, children: _jsx(SidebarItemContext.Provider, { value: { isInsideCollapse: true }, children: children }) })] }));
};
SidebarCollapse.displayName = 'Sidebar.Collapse';
export default SidebarCollapse;
