import { Footer, Navbar } from "flowbite-react";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Layout() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  useEffect(() => {}, []);

  return (
    <div className="page w-full lg:mx-14">
      <Navbar fluid={true} rounded={true}>
        <Navbar.Brand to="/">
          <span className="self-center whitespace-nowrap text-2xl font-semibold dark:text-white">
            npub.pub
          </span>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Navbar.Link href="/">Home</Navbar.Link>
          <Navbar.Link href="/contact">Contact</Navbar.Link>
        </Navbar.Collapse>
      </Navbar>

      <Outlet />

      <Footer container={true}>
        <Footer.Copyright href="#" by="NPub™" year={2023} />
        <Footer.LinkGroup>
          <Footer.Link href="#">Contact</Footer.Link>
        </Footer.LinkGroup>
      </Footer>
    </div>
  );
}
