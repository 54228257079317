import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import { forwardRef } from 'react';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
export const RangeSlider = forwardRef(({ theme: customTheme = {}, sizing = 'md', className, ...props }, ref) => {
    const theme = mergeDeep(useTheme().theme.rangeSlider, customTheme);
    return (_jsx(_Fragment, { children: _jsx("div", { "data-testid": "flowbite-range-slider", className: classNames(theme.base, className), children: _jsx("div", { className: theme.field.base, children: _jsx("input", { className: classNames(theme.field.input.base, theme.field.input.sizes[sizing]), ...props, type: "range", ref: ref }) }) }) }));
});
RangeSlider.displayName = 'RangeSlider';
