import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useTheme } from '../Flowbite/ThemeContext';
import { RatingAdvanced } from './RatingAdvanced';
import { RatingContext } from './RatingContext';
import { RatingStar } from './RatingStar';
const RatingComponent = ({ children, size = 'sm', className, ...props }) => {
    const theme = useTheme().theme.rating;
    return (_jsx(RatingContext.Provider, { value: { size }, children: _jsx("div", { className: classNames(theme.base, className), ...props, children: children }) }));
};
RatingComponent.displayName = 'Rating';
RatingStar.displayName = 'Rating.Star';
RatingAdvanced.displayName = 'Rating.Advanced';
export const Rating = Object.assign(RatingComponent, {
    Star: RatingStar,
    Advanced: RatingAdvanced,
});
