import { jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite';
const AvatarGroupCounter = ({ total, href, className, theme: customTheme = {} }) => {
    const theme = mergeDeep(useTheme().theme.avatarGroupCounter.root, customTheme);
    return (_jsxs("a", { className: classNames(theme.base, className), href: href, children: ["+", total] }));
};
AvatarGroupCounter.displayName = 'Avatar.GroupCounter';
export default AvatarGroupCounter;
