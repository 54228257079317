import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useKeyDown } from '../../hooks';
import { useTheme } from '../Flowbite/ThemeContext';
import { ModalBody } from './ModalBody';
import { ModalContext } from './ModalContext';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
const ModalComponent = ({ children, show, root = document.body, popup, size = '2xl', position = 'center', dismissible = false, onClose, className, ...props }) => {
    const theme = useTheme().theme.modal;
    // Declare a ref to store a reference to a div element.
    const containerRef = useRef(null);
    // If the current value of the ref is falsy (e.g. null), set it to a new div
    // element.
    if (!containerRef.current) {
        containerRef.current = document.createElement('div');
    }
    // If the current value of the ref is not already a child of the root element,
    // append it or replace its parent.
    if (containerRef.current.parentNode !== root) {
        root.appendChild(containerRef.current);
    }
    useEffect(() => {
        return () => {
            const container = containerRef.current;
            // If a container exists on unmount, it is removed from the DOM and
            // garbage collected.
            if (container) {
                container.parentNode?.removeChild(container);
                containerRef.current = null;
            }
        };
    }, []);
    useKeyDown('Escape', () => {
        if (dismissible && onClose) {
            onClose();
        }
    });
    const handleOnClick = (e) => {
        if (dismissible && e.target === e.currentTarget && onClose) {
            onClose();
        }
    };
    return createPortal(_jsx(ModalContext.Provider, { value: { popup, onClose }, children: _jsx("div", { "aria-hidden": !show, className: classNames(theme.base, theme.positions[position], show ? theme.show.on : theme.show.off, className), "data-testid": "modal", role: "dialog", onClick: handleOnClick, ...props, children: _jsx("div", { className: classNames(theme.content.base, theme.sizes[size]), children: _jsx("div", { className: theme.content.inner, children: children }) }) }) }), containerRef.current);
};
ModalComponent.displayName = 'Modal';
ModalHeader.displayName = 'Modal.Header';
ModalBody.displayName = 'Modal.Body';
ModalFooter.displayName = 'Modal.Footer';
export const Modal = Object.assign(ModalComponent, { Header: ModalHeader, Body: ModalBody, Footer: ModalFooter });
