import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { mergeDeep } from '../../helpers/mergeDeep';
import { useTheme } from '../Flowbite/ThemeContext';
import BreadcrumbItem from './BreadcrumbItem';
const BreadcrumbComponent = ({ children, className, theme: customTheme = {}, ...props }) => {
    const theme = mergeDeep(useTheme().theme.breadcrumb.root, customTheme);
    return (_jsx("nav", { "aria-label": "Breadcrumb", className: classNames(theme.base, className), ...props, children: _jsx("ol", { className: theme.list, children: children }) }));
};
BreadcrumbComponent.displayName = 'Breadcrumb';
export const Breadcrumb = Object.assign(BreadcrumbComponent, { Item: BreadcrumbItem });
